import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class SubscriptionsGuardService {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly isCmmsUser = toSignal(this.authService.isCmmsUser$);

  canActivate() {
    if (!this.isCmmsUser()) {
      this.router.navigate(['account/profile']);
      return false;
    }

    return true;
  }
}
