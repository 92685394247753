import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { SignupFlyoutComponent } from '../signup-flyout/signup-flyout.component';
import { RegisterFormComponent } from '../register-form/register-form.component';
import { Router } from '@angular/router';
import { EventBusService, MetaData } from '../_shared/event-bus.service';
import { AuthService } from '../_services/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'default-trial',
  standalone: true,
  imports: [SignupFlyoutComponent, RegisterFormComponent],
  templateUrl: './default-trial.component.html',
  styleUrl: './default-trial.component.scss'
})
export class DefaultTrialComponent implements OnInit {
  public showRegisterForm = signal(false);
  private readonly router = inject(Router);
  private readonly eventService = inject(EventBusService);
  private readonly authService = inject(AuthService);
  private readonly userIsLoggedIn = toSignal(this.authService.isLoggedIn$);

  constructor() {
    effect(
      () => {
        if (this.userIsLoggedIn()) {
          this.showRegisterForm.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.eventService.subscribe('SignupFlyout.Show', (callback: MetaData<boolean>) => {
      this.showRegisterForm.set(callback.data);
    });
  }

  hideRegisterForm() {
    this.showRegisterForm.set(false);
    this.router.navigate(['/login']);
  }
}
