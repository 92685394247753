import { addCommand, addCommandCallback } from '../utils';
export class AdjustEvent {
  token;
  revenue;
  currency;
  constructor(token) {
    this.token = token;
  }
  setRevenue(revenue, currency) {
    this.revenue = revenue;
    this.currency = currency;
  }
}
const adjust = {
  initialize: function (params) {
    addCommand('median://adjust/initialize', params);
  },
  trackEvent: function (adjustEvent) {
    const params = {
      token: adjustEvent.token,
      revenue: adjustEvent.revenue,
      currency: adjustEvent.currency
    };
    addCommand('median://adjust/trackEvent', params);
  },
  attributionInfo: function () {
    return addCommandCallback('median://adjust/attributionInfo');
  },
  updateSkanConversionValue: function (params) {
    return addCommandCallback('median://adjust/updateSkanConversionValue', params);
  }
};
export default adjust;