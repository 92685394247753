import { Component, output } from '@angular/core';
@Component({
  selector: 'trial-thank-you',
  standalone: true,
  imports: [],
  templateUrl: './trial-thank-you.component.html',
  styleUrl: './trial-thank-you.component.scss'
})
export class TrialThankYouComponent {
  done = output();
}
