<div class="modal-backdrop" (click)="close.emit()"></div>
<section class="signup-modal">
  <header>
    <img src="/assets/images/limble-search-logo.svg" class="logo" />
    <fa-icon class="close" [icon]="faXmarkLarge" size="lg" (click)="close.emit()"></fa-icon>
  </header>
  <div class="flyout-container">
    <ng-content></ng-content>
  </div>
</section>

