import { addCommandCallback } from '../utils';
const cordial = {
  setContact: function (params) {
    return addCommandCallback('median://cordial/setContact', params);
  },
  unsetContact: function (params) {
    return addCommandCallback('median://cordial/unsetContact', params);
  },
  permissionStatus: function (params) {
    return addCommandCallback('median://cordial/permissionStatus', params, true);
  },
  requestPermission: function (params) {
    return addCommandCallback('median://cordial/requestPermission', params, true);
  }
};
export default cordial;