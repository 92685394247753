import { addCommand, addCommandCallback } from '../utils';
const ios = {
  window: {
    open: function (url, mode = 'blank') {
      addCommand('median://window/open', {
        url,
        mode
      });
    },
    setWindowOpenHideNavbar: function (windowOpenHideNavbar) {
      addCommand('median://window/setWindowOpenHideNavbar', {
        windowOpenHideNavbar
      });
    }
  },
  geoLocation: {
    requestLocation: function () {
      addCommand('median://geolocationShim/requestLocation');
    },
    startWatchingLocation: function () {
      addCommand('median://geolocationShim/startWatchingLocation');
    },
    stopWatchingLocation: function () {
      addCommand('median://geolocationShim/stopWatchingLocation');
    }
  },
  attconsent: {
    request: function (params) {
      return addCommandCallback('median://ios/attconsent/request', params);
    },
    status: function (params) {
      return addCommandCallback('median://ios/attconsent/status', params);
    }
  },
  backgroundAudio: {
    start: function () {
      addCommand('median://backgroundAudio/start');
    },
    end: function () {
      addCommand('median://backgroundAudio/end');
    }
  },
  contextualNavToolbar: {
    set: function (params) {
      addCommand('median://ios/contextualNavToolbar/set', params);
    }
  }
};
export default ios;