import { addCommand, addCommandCallback } from '../utils';
const socialLogin = {
  facebook: {
    login: function (params) {
      if (params?.callback) {
        return addCommandCallback('median://socialLogin/facebook/login', params);
      }
      addCommand('median://socialLogin/facebook/login', params);
    },
    logout: function (params) {
      if (params?.callback) {
        return addCommandCallback('median://socialLogin/facebook/logout', params);
      }
      addCommand('median://socialLogin/facebook/logout', params);
    }
  },
  google: {
    login: function (params) {
      if (params?.callback) {
        return addCommandCallback('median://socialLogin/google/login', params);
      }
      addCommand('median://socialLogin/google/login', params);
    },
    logout: function (params) {
      if (params?.callback) {
        return addCommandCallback('median://socialLogin/google/logout', params);
      }
      addCommand('median://socialLogin/google/logout', params);
    }
  },
  apple: {
    login: function (params) {
      if (params?.callback) {
        return addCommandCallback('median://socialLogin/apple/login', params);
      }
      addCommand('median://socialLogin/apple/login', params);
    }
  }
};
export default socialLogin;