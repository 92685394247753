import { effect, Injectable, Injector, signal, untracked } from '@angular/core';
export interface MetaData<T> {
  data: T;
  timestamp: Date;
}

interface EventBus<N extends string, M> {
  name: N;
  metaData: MetaData<M>;
}

type EVENT_MESSAGE =
  | 'INIT'
  | 'CMMSSignupFlyout.Show'
  | 'NonUserSignupFlyout.Show'
  | 'SignupFlyout.Show'
  | 'CMMSSignUpTrialThankYouModal.Show';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private eventBus = signal<EventBus<EVENT_MESSAGE, any>>({
    name: 'INIT',
    metaData: {
      data: {},
      timestamp: new Date()
    }
  });

  constructor(private readonly injector: Injector) {}

  emit<T>(name: EVENT_MESSAGE, data: T) {
    this.eventBus.set({
      name,
      metaData: {
        data,
        timestamp: new Date()
      }
    });
  }

  private matchQuery(key: EVENT_MESSAGE, query: string) {
    if (key === query) return true;

    if (query === '*') return true;

    return false;
  }

  subscribe<T>(query: EVENT_MESSAGE | '*', callback: (metaData: MetaData<T>) => void) {
    effect(
      () => {
        const data = this.eventBus();

        if (this.matchQuery(data.name, query) && data.metaData.timestamp) {
          untracked(() => callback(data.metaData));
        }
      },
      { injector: this.injector }
    );
  }
}
