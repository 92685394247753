import { addCommand, addCommandCallback } from '../utils';
const clipboard = {
  set: function (params) {
    addCommand('median://clipboard/set', params);
  },
  get: function (params) {
    return addCommandCallback('median://clipboard/get', params);
  }
};
const config = {
  set: function (params) {
    addCommand('median://config/set', params);
  }
};
const connectivity = {
  get: function (params) {
    return addCommandCallback('median://connectivity/get', params);
  },
  subscribe: function (params) {
    return addCommandCallback('median://connectivity/subscribe', params, true);
  },
  unsubscribe: function () {
    addCommand('median://connectivity/unsubscribe');
  }
};
const contextMenu = {
  setEnabled: function (enabled) {
    addCommand('median://contextMenu/setEnabled', {
      enabled
    });
  },
  setLinkActions: function (actions) {
    addCommand('median://contextMenu/setLinkActions', {
      actions
    });
  }
};
const deviceInfo = function (params) {
  return addCommandCallback('median://run/median_device_info', params, true);
};
const internalExternal = {
  set: function (params) {
    addCommand('median://internalExternal/set', params);
  }
};
const keyboard = {
  info: function (params) {
    return addCommandCallback('median://keyboard/info', params);
  },
  listen: function (callback) {
    addCommand('median://keyboard/listen', {
      callback
    });
  },
  showAccessoryView: function (visible) {
    addCommand('median://keyboard/showAccessoryView', {
      visible
    });
  }
};
const nativebridge = {
  custom: function (params) {
    return addCommandCallback('median://nativebridge/custom', params);
  },
  multi: function (params) {
    addCommand('median://nativebridge/multi', params);
  }
};
const navigationLevels = {
  set: function (data) {
    addCommand('median://navigationLevels/set', {
      persist: data.persist,
      data
    });
  },
  setCurrent: function (params) {
    addCommand('median://navigationLevels/set', params);
  },
  revert: function () {
    addCommand('median://navigationLevels/set?persist=true');
  }
};
const navigationMaxWindows = {
  set: function (maxWindows, autoClose) {
    addCommand('median://navigationMaxWindows/set', {
      data: maxWindows,
      autoClose: autoClose,
      persist: true
    });
  },
  setCurrent: function (maxWindows, autoClose) {
    addCommand('median://navigationMaxWindows/set', {
      data: maxWindows,
      autoClose: autoClose
    });
  }
};
const navigationTitles = {
  set: function (params) {
    addCommand('median://navigationTitles/set', {
      persist: params.persist,
      data: params
    });
  },
  setCurrent: function (params) {
    addCommand('median://navigationTitles/setCurrent', params);
  },
  revert: function () {
    addCommand('median://navigationTitles/set?persist=true');
  }
};
const open = {
  appSettings: function () {
    addCommand('median://open/app-settings');
  }
};
const registration = {
  send: function (customData) {
    addCommand('median://registration/send', {
      customData
    });
  }
};
const run = {
  deviceInfo: function () {
    addCommand('median://run/median_device_info');
  }
};
const screen = {
  setBrightness: function (data) {
    const params = ['number', 'string'].includes(typeof data) ? {
      brightness: data
    } : data;
    addCommand('median://screen/setBrightness', params);
  },
  setMode: function (params) {
    addCommand('median://screen/setMode', params);
  },
  keepScreenOn: function (params) {
    addCommand('median://screen/keepScreenOn', params);
  },
  keepScreenNormal: function () {
    addCommand('median://screen/keepScreenNormal');
  }
};
const share = {
  sharePage: function (params) {
    addCommand('median://share/sharePage', params);
  },
  downloadFile: function (params) {
    addCommand('median://share/downloadFile', params);
  },
  downloadImage: function (params) {
    addCommand('median://share/downloadImage', params);
  }
};
const sidebar = {
  setItems: function (params) {
    addCommand('median://sidebar/setItems', params);
  },
  getItems: function (params) {
    return addCommandCallback('median://sidebar/getItems', params);
  }
};
const statusbar = {
  set: function (params) {
    addCommand('median://statusbar/set', params);
  },
  matchBodyBackgroundColor: function (params) {
    addCommand('median://statusbar/matchBodyBackgroundColor', params);
  }
};
const tabNavigation = {
  selectTab: function (tabIndex) {
    addCommand('median://tabs/select/' + tabIndex);
  },
  deselect: function () {
    addCommand('median://tabs/deselect');
  },
  setTabs: function (tabs) {
    addCommand('median://tabs/setTabs', {
      tabs
    });
  }
};
const webview = {
  clearCache: function () {
    addCommand('median://webview/clearCache');
  },
  clearCookies: function () {
    addCommand('median://webview/clearCookies');
  },
  reload: function () {
    addCommand('median://webview/reload');
  }
};
const window = {
  open: function (url, mode = 'blank') {
    addCommand('median://window/open', {
      url,
      mode
    });
  },
  close: function () {
    addCommand('median://window/close');
  }
};
export { clipboard, config, connectivity, contextMenu, deviceInfo, internalExternal, keyboard, nativebridge, navigationLevels, navigationMaxWindows, navigationTitles, open, registration, run, screen, share, sidebar, statusbar, tabNavigation, webview, window };