<div class="modal-backdrop"></div>
<div class="modal">
    <div class="modal-header">
        <button (click)="close.emit()">
            <fa-icon class="close" [icon]="faXmarkLarge" size="lg"></fa-icon>
        </button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
</div>