import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CONFIG } from '../../environments/environment';

const itemsUrl = CONFIG.API_URL + 'items';

const SYNC_FREQUENCY = 10000;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  private lastSync: number = 0;
  private itemsSeen: any = {};
  private itemViews: any = [];
  private pagePositions: any = {};
  private itemPagePositions: any = [];
  private sentPagePositions: boolean = false;

  constructor(private http: HttpClient) {
    setInterval(() => {
      this.saveItemsSeen();
    }, SYNC_FREQUENCY);
  }

  getItem(itemId: number, itemGroupValueId: number): Observable<any> {
    const request = this.http.get(
      `${itemsUrl}/${itemId}${itemGroupValueId ? `?group=${itemGroupValueId}` : ''}`,
      httpOptions
    );
    return request;
  }

  updateItem(itemID: number, user_hidden: boolean): Observable<any> {
    const request = this.http.patch(
      itemsUrl + `/${itemID}`,
      {
        user_hidden
      },
      httpOptions
    );
    return request;
  }

  updateLabel(itemID: number, key: string, value: any): Observable<any> {
    const request = this.http.patch(
      itemsUrl + `/${itemID}/labels`,
      {
        key,
        value
      },
      httpOptions
    );
    return request;
  }

  trackClick(itemID: any, type: any): Observable<any> {
    const request = this.http.post(
      itemsUrl + `/${itemID}/click`,
      {
        type
      },
      httpOptions
    );
    return request;
  }

  trackView(itemID: any) {
    if (this.itemsSeen[itemID]) {
      return;
    }
    this.itemsSeen[itemID] = true;

    let now = new Date().getTime();
    this.itemViews.push({ id: itemID, time: now });
  }

  saveItemsSeen() {
    this.lastSync = new Date().getTime();

    if (this.itemViews.length == 0) {
      return;
    }

    let itemIds = this.itemViews.map((item) => +item.id);
    this.itemViews = [];

    this.http.post(itemsUrl + '/views', { itemIds }, httpOptions).subscribe((response) => {});
  }

  resetPagePositions() {
    this.sentPagePositions = false;
    this.pagePositions = [];
  }

  trackPagePosition(itemID: number, position: number, itemGroupValueId: number | undefined) {
    if (this.sentPagePositions || this.pagePositions[itemID]) {
      return;
    }
    this.pagePositions[itemID] = true;

    const positionInfo = {
      pagePosition: position,
      pagePositionGroupId: itemGroupValueId || null
    };
    this.itemPagePositions.push({ id: itemID, positionInfo });
  }

  savePagePositions() {
    if (this.sentPagePositions) {
      return;
    }
    this.sentPagePositions = true;
    this.http.post(itemsUrl + `/pagePositions`, this.itemPagePositions, httpOptions).subscribe((response) => {});
  }
}
