import { addCallbackFunction, addCommand, addCommandCallback } from '../utils';
const onesignal = {
  /**
   * @deprecated Use Median.onesignal.info() instead
   */
  run: {
    onesignalInfo: function () {
      addCommand('median://onesignal/info', {
        callback: 'median_onesignal_info'
      });
    }
  },
  onesignalInfo: function (params) {
    return addCommandCallback('median://onesignal/info', params, true);
  },
  info: function (params) {
    return addCommandCallback('median://onesignal/info', params, true);
  },
  register: function () {
    addCommand('median://onesignal/register');
  },
  userPrivacyConsent: {
    grant: function () {
      addCommand('median://onesignal/userPrivacyConsent/grant');
    },
    revoke: function () {
      addCommand('median://onesignal/userPrivacyConsent/revoke');
    }
  },
  tags: {
    getTags: function (params) {
      return addCommandCallback('median://onesignal/tags/get', params);
    },
    setTags: function (params) {
      addCommand('median://onesignal/tags/set', params);
    },
    deleteTags: function (params) {
      addCommand('median://onesignal/tags/delete', params);
    }
  },
  showTagsUI: function () {
    addCommand('median://onesignal/showTagsUI');
  },
  promptLocation: function () {
    addCommand('median://onesignal/promptLocation');
  },
  iam: {
    addTrigger: function (params) {
      addCommand('median://onesignal/iam/addTrigger', params);
    },
    addTriggers: function (params) {
      addCommand('median://onesignal/iam/addTriggers', params);
    },
    removeTriggerForKey: function (key) {
      const params = {
        key: key
      };
      addCommand('median://onesignal/iam/removeTriggerForKey', params);
    },
    getTriggerValueForKey: function (key) {
      const params = {
        key: key
      };
      addCommand('median://onesignal/iam/getTriggerValueForKey', params);
    },
    pauseInAppMessages: function () {
      addCommand('median://onesignal/iam/pauseInAppMessages?pause=true');
    },
    resumeInAppMessages: function () {
      addCommand('median://onesignal/iam/pauseInAppMessages?pause=false');
    },
    setInAppMessageClickHandler: function (handlerFunction) {
      const handler = addCallbackFunction(handlerFunction, true);
      addCommand('median://onesignal/iam/setInAppMessageClickHandler', {
        handler
      });
    }
  },
  externalUserId: {
    set: function (params) {
      return addCommandCallback('median://onesignal/externalUserId/set', params);
    },
    remove: function (params) {
      return addCommandCallback('median://onesignal/externalUserId/remove', params);
    }
  },
  enableForegroundNotifications: function (enabled) {
    addCommand('median://onesignal/enableForegroundNotifications', {
      enabled
    });
  },
  badge: {
    set: function (count) {
      addCommand('median://onesignal/badge/set', {
        count
      });
    }
  },
  login: function (externalId, params = {}) {
    return addCommandCallback('median://onesignal/login', {
      externalId,
      ...params
    });
  },
  logout: function (params) {
    return addCommandCallback('median://onesignal/logout', params);
  }
};
export default onesignal;