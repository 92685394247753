import { Component, input } from '@angular/core';

@Component({
  selector: 'plan-status-pill',
  standalone: true,
  imports: [],
  templateUrl: './plan-status-pill.component.html',
  styleUrl: './plan-status-pill.component.scss'
})
export class PlanStatusPillComponent {
  planStatus = input.required<string>();
}
