<div id="app" [attr.app-version]="version">
  <navigation [isMobile]="isMobile()" [isLoggedIn]="isLoggedIn()" [responsivePositioning]="responsivePositioning()" />
  <search-complete-notification />
  <main [ngClass]="responsivePositioning() ? 'main-static' : 'main-fixed'">
    <default-trial />
    <cmms-trial />

    @switch (userAccessStatus()) {
      @case (userAccessStatusEnum.ExpiredTrial) {
        <page-banner class="trial-expired">
          <div icon>
            <fa-icon [icon]="clockIcon" />
          </div>
          <div content class="msg">
            Your free trial has ended. Upgrade today to always get the best price.
            <a routerLink="/extension" class="cta">Contact Sales</a>
          </div>
        </page-banner>
      }

      @case (userAccessStatusEnum.Trial) {
        @if (hasStartedTrial()) {
          <page-banner>
            <div icon>
              <fa-icon [icon]="clockIcon" />
            </div>
            <div content class="msg">
              Your free trial is ending in <b>{{ daysInTrialRemaining }}</b> days. Upgrade now before losing access.
              <a routerLink="/extension" class="cta">Contact Sales</a>
            </div>
          </page-banner>
        }        
      }

      @default {
        @if (routeExtensionBannerEnabled() && showExtensionBanner) {
          <page-banner
           (closedBanner)="handleBannerClose()"
          >
            <div icon>
              <div class="pill">New</div>
            </div>
            <div content class="msg">
              Use Limble Search on Any Website.
              <a routerLink="/extension" class="cta">Learn More</a>
            </div>
          </page-banner>
        }
      }
    }

    <router-outlet></router-outlet>
    
  </main>
</div>
