import { addCommand, addCommandCallback } from '../utils';
const braze = {
  changeUser: function (params) {
    return addCommandCallback('median://braze/changeUser', params, true);
  },
  notificationEnabled: function (params) {
    return addCommandCallback('median://braze/notificationEnabled', params, true);
  },
  promptNotification: function (params) {
    return addCommandCallback('median://braze/promptNotification', params, true);
  },
  logCustomEvent: function (eventName, data) {
    addCommand('median://braze/logCustomEvent', {
      eventName,
      data
    });
  }
};
export default braze;