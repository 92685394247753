{
  "nm": "Frame 38177",
  "ddd": 0,
  "h": 16,
  "w": 256,
  "meta": { "g": "LottieFiles Figma v45" },
  "layers": [
    {
      "ty": 4,
      "nm": "Card",
      "sr": 1,
      "st": 0,
      "op": 61,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "s": [100, 100], "t": 60 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "s": [0], "t": 60 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "s": [100], "t": 60 }
          ]
        }
      },
      "shapes": [],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "gradient-walker",
      "sr": 1,
      "st": 0,
      "op": 61,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [87.5, 21], "t": 0 },
            { "s": [87.5, 12.5], "t": 60 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "s": [100, 100], "t": 60 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [-87.5, 11], "t": 0 },
            { "s": [404.5, 3.5], "t": 60 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "s": [0], "t": 60 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "s": [100], "t": 60 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [167, 0],
                      [175, 8],
                      [175, 34],
                      [167, 42],
                      [8, 42],
                      [0, 34]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [0, 0],
                      [175, 0],
                      [175, 0],
                      [175, 25],
                      [175, 25],
                      [0, 25],
                      [0, 25]
                    ]
                  }
                ],
                "t": 60
              }
            ]
          }
        },
        {
          "ty": "gf",
          "bm": 0,
          "hd": false,
          "nm": "",
          "e": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [174.38499450683594, 7.89408016204834], "t": 0 },
              { "s": [173.2530059814453, -6.536920070648193], "t": 60 }
            ]
          },
          "g": {
            "p": 3,
            "k": {
              "a": 1,
              "k": [
                {
                  "o": { "x": 0, "y": 0 },
                  "i": { "x": 0.58, "y": 1 },
                  "s": [
                    0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                    0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                    0.9490588265680799
                  ],
                  "t": 0
                },
                {
                  "s": [
                    0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                    0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                    0.9490588265680799
                  ],
                  "t": 60
                }
              ]
            }
          },
          "t": 1,
          "a": { "a": 0, "k": 0 },
          "h": { "a": 0, "k": 0 },
          "s": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [-6.029729888723523e-7, 18.357099533081055],
                "t": 0
              },
              { "s": [-6.029729888723523e-7, 10.926899909973145], "t": 60 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "s": [100], "t": 60 }
            ]
          }
        }
      ],
      "ind": 2,
      "parent": 1,
      "tp": 4
    },
    {
      "ty": 4,
      "nm": "background",
      "sr": 1,
      "st": 0,
      "op": 61,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "s": [100, 100], "t": 60 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "s": [0], "t": 60 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "s": [100], "t": 60 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [307, 0],
                      [315, 8],
                      [315, 8],
                      [307, 16],
                      [8, 16],
                      [0, 8]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [0, 0],
                      [256, 0],
                      [256, 0],
                      [256, 16],
                      [256, 16],
                      [0, 16],
                      [0, 16]
                    ]
                  }
                ],
                "t": 60
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0.9491, 0.9491, 0.9491], "t": 0 },
              { "s": [0.9491, 0.9491, 0.9491], "t": 60 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "s": [100], "t": 60 }
            ]
          }
        }
      ],
      "ind": 3,
      "parent": 1,
      "tp": 4
    },
    {
      "ty": 4,
      "nm": "mask",
      "sr": 1,
      "st": 0,
      "op": 61,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "td": 1,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "s": [100, 100], "t": 60 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "s": [0], "t": 60 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "s": [100], "t": 60 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [248, 0],
                      [256, 8],
                      [248, 16],
                      [8, 16],
                      [0, 8]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [248, 0],
                      [256, 8],
                      [248, 16],
                      [8, 16],
                      [0, 8]
                    ]
                  }
                ],
                "t": 60
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0.9295, 0.953, 0.9647], "t": 0 },
              { "s": [0.9295, 0.953, 0.9647], "t": 60 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "s": [100], "t": 60 }
            ]
          }
        }
      ],
      "ind": 4,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "bg",
      "sr": 1,
      "st": 0,
      "op": 61,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "s": [100, 100], "t": 60 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [128, 8], "t": 0 },
            { "s": [128, 8], "t": 60 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "s": [0], "t": 60 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "s": [100], "t": 60 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [248, 0],
                      [256, 8],
                      [256, 8],
                      [248, 16],
                      [8, 16],
                      [0, 8]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [248, 0],
                      [256, 8],
                      [256, 8],
                      [248, 16],
                      [8, 16],
                      [0, 8]
                    ]
                  }
                ],
                "t": 60
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [1, 1, 1], "t": 0 },
              { "s": [1, 1, 1], "t": 60 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "s": [100], "t": 60 }
            ]
          }
        }
      ],
      "ind": 5,
      "parent": 1
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 60.06,
  "ip": 0,
  "assets": []
}
