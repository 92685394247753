@if (showUpgradeModal()) {
  <modal (close)="hideUpgradeModal()">
    <div class="upgrade-container">
      <h1>Upgrade Confirmation</h1>
      <p>Are you sure you want to upgrade your plan? By clicking upgrade, an email will be sent to the super user of your account.</p>
      <button (click)="upgradeConfirmed()">Upgrade</button>
    </div>
    
  </modal>
}

@if (showThankYouModal()) {
  <modal (close)="hideThankYouModal()">
    <div class="upgrade-container">
      <img src="assets/images/thank-you-icon.svg" />
      <h1>Thank you!</h1>
      <p>An email has been sent to the super user of your account.</p>
      <button (click)="hideThankYouModal()">Done</button>
    </div>
  </modal>
}

<div class="plan-and-usage-container">
  <div class="section-header">Plan Overview</div>
  @if (isSuperUser()) {
      <div class="plan-container">
        <plan-price [plan]="plan()" />
        <plan-status-pill [planStatus]="planStatus()" />
      </div>
      <total-searches-card [searches]="searches()" />
      <a class="cancel-button" href="#">Cancel Subscription</a>
    }
    @else {
      <div class="plan-container">
        <div class="plan-details">
          <h4>{{ plan().name }}</h4>
            <p>{{ searches().total }} searches/month</p>
          </div>
          <plan-status-pill [planStatus]="planStatus()" />
        </div>
        <total-searches-card [searches]="searches()" />
        <button (click)="sendUpgradeEmail()" class="upgrade-button">Upgrade Now</button>
    }

</div>
