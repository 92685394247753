import { addCommand, addCommandCallback } from '../utils';
const iap = {
  purchase: function (params) {
    const productId = params.productID;
    params.productID = null;
    return addCommandCallback('median://purchase/' + productId, params);
  },
  purchases: function (params) {
    return addCommandCallback('median://iap/purchases', params);
  },
  manageSubscription: function (params) {
    addCommand('median://iap/manageSubscription', params);
  },
  manageAllSubscriptions: function () {
    addCommand('median://iap/manageAllSubscriptions');
  },
  restorePurchases: function () {
    addCommand('median://iap/restorePurchases');
  },
  info: function () {
    return addCommandCallback('median://iap/info');
  },
  refresh: function () {
    addCommand('median://iap/refresh');
  }
};
export default iap;