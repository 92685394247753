import { Component, input } from '@angular/core';

@Component({
  selector: 'total-searches-card',
  standalone: true,
  imports: [],
  templateUrl: './total-searches-card.component.html',
  styleUrl: './total-searches-card.component.scss'
})
export class TotalSearchesCardComponent {
  searches = input.required<{ used: number; total: number; resetDate: string }>();
}
