import { Component, computed, ElementRef, HostListener, Renderer2, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgClass } from '@angular/common';
import { Router, RouterOutlet, RouterLink, NavigationEnd, ActivatedRoute } from '@angular/router';

import { AuthService, UserAccessStatus } from './_services/auth.service';
import { NotificationService } from './_services/notification.service';
import { isMobile, isIOSUserAgent } from './_util/mobile.util';

import { DropdownTextItemComponent, DropdownComponent, IconComponent } from '@limblecmms/lim-ui';
import { PartSearchFieldComponent } from './part-search-field/part-search-field.component';
import { SearchService } from './_services/search.service';
import _ from 'lodash';
import { ItemsService } from './_services/items.service';
import { SearchCompleteNotificationComponent } from './custom-notifications/search-complete/search-complete-notification.component';
import { SignupFlyoutComponent } from './signup-flyout/signup-flyout.component';
import { PageBanner } from './page-banner/page-banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPuzzlePiece, faClock } from '@fortawesome/pro-regular-svg-icons';

import { CONFIG } from '../environments/environment';
import { RegisterFormComponent } from './register-form/register-form.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { EventBusService, MetaData } from './_shared/event-bus.service';
import { CmmsTrialComponent } from './cmms-trial/cmms-trial.component';
import { DefaultTrialComponent } from './default-trial/default-trial.component';
import { NavigationComponent } from './navigation/navigation.component';

function isElementInViewport(el, container: Element | null = null) {
  // Default container to the window
  let containerTop = 0;
  let containerLeft = 0;
  let containerBottom = window.innerHeight || document.documentElement.clientHeight;
  let containerRight = window.innerWidth || document.documentElement.clientWidth;

  if (container && typeof container.getBoundingClientRect === 'function') {
    var containerRect = container.getBoundingClientRect();
    containerTop = containerRect.top;
    containerLeft = containerRect.left;
    containerBottom = containerRect.bottom;
    containerRight = containerRect.right;
  }

  var rect = el.getBoundingClientRect();

  return (
    rect.top >= containerTop && rect.left >= containerLeft && rect.bottom <= containerBottom && rect.right <= containerRight
  );
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    SearchCompleteNotificationComponent,
    FontAwesomeModule,
    SignupFlyoutComponent,
    PageBanner,
    RegisterFormComponent,
    CmmsTrialComponent,
    DefaultTrialComponent,
    NavigationComponent,
    NgClass
  ]
})
export class AppComponent {
  public isLoggedIn = signal(false);
  public user: any = {};
  public showMobileMenu: boolean = false;
  public isMobile = signal(isMobile());
  public isIOS = signal(isIOSUserAgent());
  public showExtensionBanner = false;
  public hasBrowserExtension = false;
  public responsivePositioning = signal(false);
  public itemElements: any = [];
  public itemsSeen: any = {};
  public showSignupBanner: boolean = false;
  public isMarketingMode: boolean = false;
  public userAccessStatus = toSignal(this.authService.userAccessStatus$);
  public userAccessStatusEnum = UserAccessStatus;
  public daysInTrialRemaining: number | null = null;
  public hasStartedTrial = toSignal(this.authService.hasStartedTrial$);

  public faPuzzlePiece: any = faPuzzlePiece;
  public clockIcon = faClock;

  showHamburger = computed(() => {
    if (this.router.url !== '/login' && this.router.url !== '/register') {
      return true;
    }

    return false;
  });

  showLinks = computed(() => {
    if (!this.isLoggedIn() && this.router.url === '/') {
      return true;
    }

    if (this.router.url.includes('/explored')) {
      return true;
    }

    return false;
  });
  showFooter = computed(() => {
    if (this.isMobile() && this.isIOS()) {
      if (this.router.url.includes('results') || this.router.url.includes('details')) {
        return true;
      }
    }

    return false;
  });

  public loggedInSub?: Subscription;
  public daysInTrialRemainingSub?: Subscription;

  @HostListener('window:beforeunload', ['$event'])
  saveItemsSeen(event) {
    this.itemsService.saveItemsSeen();
  }

  @HostListener('window:itemView', ['$event'])
  @HostListener('window:DOMContentLoaded', ['$event'])
  @HostListener('window:load', ['$event'])
  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  checkItemsSeen(event) {
    if (this.isMarketingMode) {
      return;
    }

    this.itemElements = document.querySelectorAll('div.item');

    if (!this.itemElements || this.itemElements.length == 0) {
      return;
    }

    let container = null;
    if (event && event.detail && event.detail?.container) {
      container = event.detail.container;
    }

    // Loop through each element
    for (var i = 0; i < this.itemElements.length; i++) {
      let el = this.itemElements[i];

      if (el.dataset?.itemId && isElementInViewport(el, container)) {
        this.itemsService.trackView(el.dataset?.itemId);
      }
    }
  }

  version = CONFIG.version;
  constructor(
    private authService: AuthService,
    public notificationService: NotificationService,
    public router: Router,
    public route: ActivatedRoute,
    public searchService: SearchService,
    private renderer: Renderer2,
    private el: ElementRef,
    private itemsService: ItemsService,
    private readonly eventService: EventBusService
  ) {
    // Throttle scrolling events as we monitor which items are seen so we don't slow things down
    this.checkItemsSeen = _.throttle(this.checkItemsSeen, 250);
  }

  closeExtensionBanner() {
    this.showExtensionBanner = false;

    // Set the local storage value to not show the banner again
    localStorage.setItem('extensionBannerHidden', '1');
  }

  routeExtensionBannerEnabled() {
    return (
      this.router.url === '' ||
      this.router.url === '/' ||
      this.router.url.includes('results') ||
      this.router.url.includes('details')
    );
  }

  ngOnInit(): void {
    // Get the specific element
    const element = this.el.nativeElement.querySelector('main');

    this.searchService._isMarketingMode.subscribe((isMarketingMode) => {
      this.showSignupBanner = isMarketingMode;
      this.isMarketingMode = isMarketingMode;
    });

    // Listen for the scroll event on the specific element
    this.renderer.listen(element, 'scroll', (event) => {
      this.checkItemsSeen(event);
    });

    this.loggedInSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn.set(isLoggedIn);

      if (isLoggedIn) {
        this.user = this.authService.getUser();
        this.authService.initMonitoring(this.user);
      }
    });

    this.daysInTrialRemainingSub = this.authService.daysInTrialRemaining$.subscribe((daysLeft) => {
      this.daysInTrialRemaining = daysLeft;
    });

    // Pulls the latest logged in user info from the backend and updates the user stored in local storage
    this.authService.refreshUser();

    this.positioningMainDiv();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.positioningMainDiv();
      }
    });

    this.checkExtensionIntalled();
  }

  checkExtensionIntalled() {
    // Wait for extension to be initialized
    setTimeout(() => {
      let limblePluginEl = window.document.getElementById('limplug-extension-data');

      if (limblePluginEl) {
        try {
          let limblePluginData = JSON.parse(limblePluginEl.innerText);
          this.hasBrowserExtension = limblePluginData?.extensionInstalled;
        } catch (error) {
          console.error('Error parsing limble plugin data', error);
        }
      }

      if (
        !this.hasBrowserExtension &&
        localStorage.getItem('extensionBannerHidden') !== '1' &&
        !this.isMobile() &&
        !this.showSignupBanner
      ) {
        this.showExtensionBanner = true;
      } else {
        this.showExtensionBanner = false;
      }
    }, 1000);
  }

  positioningMainDiv() {
    const responsiveRoutes = ['results', 'details'];
    const routePresent = responsiveRoutes.some((route) => this.router.url.includes(route));

    if (routePresent) {
      if (this.isMobile() === true) {
        this.responsivePositioning.set(true);
      } else {
        this.responsivePositioning.set(false);
      }
    } else {
      this.responsivePositioning.set(false);
    }
  }

  handleBannerClose() {
    if (this.userAccessStatus() === UserAccessStatus.Full) {
      this.closeExtensionBanner();
    }
  }

  ngOnDestroy(): void {
    this.loggedInSub?.unsubscribe();
    this.daysInTrialRemainingSub?.unsubscribe();
  }
}
