import { Component, computed, inject, input } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'tab',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss'
})
export class TabComponent {
  name = input.required<string>();
  link = input.required<string>();
}
