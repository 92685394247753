import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from 'src/app/_services/auth.service';
import { PlanPriceComponent } from './plan-price/plan-price.component';
import { TotalSearchesCardComponent } from './total-searches-card/total-searches-card.component';
import { PlanStatusPillComponent } from './plan-status-pill/plan-status-pill.component';
import { ModalComponent } from '../../modal/modal.component';
import { SubscriptionService } from 'src/app/_services/subscription.service';

@Component({
  selector: 'subscriptions',
  standalone: true,
  imports: [PlanPriceComponent, TotalSearchesCardComponent, PlanStatusPillComponent, ModalComponent],
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent {
  private readonly authService = inject(AuthService);
  private readonly subscriptionService = inject(SubscriptionService);
  isSuperUser = toSignal(this.authService.superUser$);

  showUpgradeModal = signal(false);
  showThankYouModal = signal(false);

  plan = signal({
    name: 'Free Trial',
    price: '$0',
    period: 'per month',
    description: 'Then $150 per month ($1800/year, billed annually)',
    daysLeft: 7
  });

  searches = signal({
    used: 35,
    total: 50,
    resetDate: '12/10/2024'
  });

  planStatus = computed(() => {
    return `${this.plan().daysLeft} days left`;
  });

  sendUpgradeEmail() {
    this.showUpgradeModal.set(true);
  }

  hideUpgradeModal() {
    this.showUpgradeModal.set(false);
  }

  hideThankYouModal() {
    this.showThankYouModal.set(false);
  }

  upgradeConfirmed() {
    this.showUpgradeModal.set(false);

    this.subscriptionService.upgradeSubscription().subscribe({
      next: (data: any) => {
        this.showThankYouModal.set(true);
      },
      error: (error) => {
        this.showThankYouModal.set(true);
        console.error(error);
      }
    });
  }
}
