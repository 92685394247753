import { addCommand, addCommandCallback } from '../utils';
const documentScanner = {
  scanPage: function (params) {
    if (params?.callback) {
      return addCommandCallback('median://documentScanner/scanPage', params);
    }
    addCommand('median://documentScanner/scanPage', params);
  },
  init: function (params) {
    addCommand('median://documentScanner/scanPage', params);
  }
};
export default documentScanner;