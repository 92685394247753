import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CONFIG } from '../../environments/environment';
import { TrialType } from '../_types/trialType';
import { AuthService } from './auth.service';

const trialUrl = CONFIG.API_URL + 'trial';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class TrialService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);

  createUserTrial(trialType: TrialType): Subscription {
    const user = this.authService.getUser();
    if (!user) {
      throw new Error('User not found');
    }

    const result = this.http.post(trialUrl, { userId: user.id, trialType }, httpOptions).subscribe({
      next: (data: any) => {
        this.authService.saveUser(data.user);
        this.authService.checkUserAccessStatus(data.user);
      },
      error: (response) => {
        // Using the auth service's error subject to maintain consistency
        this.authService['_error'].next(response.error.message);
      }
    });

    return result;
  }
}
