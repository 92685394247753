import { addCommandCallback } from '../utils';
const keychainSwift = {
  set: function (params) {
    return addCommandCallback('gonative://keychainSwift/set', params);
  },
  get: function (params) {
    return addCommandCallback('gonative://keychainSwift/get', params);
  },
  delete: function (params) {
    return addCommandCallback('gonative://keychainSwift/delete', params);
  },
  deleteAll: function (params) {
    return addCommandCallback('gonative://keychainSwift/deleteAll', params);
  }
};
export default keychainSwift;