import { DatePipe, NgClass } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { isMobile } from '../../_util/mobile.util';
import { NotificationService, NotificationType } from 'src/app/_services/notification.service';
import { AuthService } from 'src/app/_services/auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Subscription, take } from 'rxjs';
import { FeatureService } from 'src/app/_services/feature.service';
import { CONFIG } from 'src/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'profile',
  standalone: true,
  imports: [FormsModule, NgClass, FontAwesomeModule, DatePipe],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit, OnDestroy {
  form: any = {
    firstName: null,
    lastName: null,
    companyName: null,
    email: null,
    password: undefined
  };
  user: any;

  private readonly authService = inject(AuthService);
  private readonly notificationService = inject(NotificationService);
  private readonly featureService = inject(FeatureService);

  private featuresSub?: Subscription;
  private isLoggedIn = toSignal(this.authService.isLoggedIn$);

  public isCmmsUser = toSignal(this.authService.isCmmsUser$);
  public userProfileData = this.authService.userProfileData$;
  public isMobile: boolean = false;
  public showApiKey: boolean = false;
  public apiKey = toSignal(this.authService.apiKey$);
  public apiKeyLastRefreshed: Date | null = null;
  public apiDocUrl = `${CONFIG.API_URL}docs/search-docs`;
  public arrowUpRightFromSquare = faArrowUpRightFromSquare;

  ngOnInit(): void {
    if (this.isLoggedIn()) {
      this.userProfileData.pipe(take(1)).subscribe((userData) => {
        if (userData) {
          this.form.firstName = userData.firstName;
          this.form.lastName = userData.lastName;
          this.form.companyName = userData.companyName;
          this.form.email = userData.email;
        }
      });
      this.authService.getUserData();
    }
    this.isMobile = isMobile();

    this.featuresSub = this.featureService.features$.subscribe((features) => {
      this.showApiKey = this.featureService.enabled('search-external-api');
    });
  }

  ngOnDestroy(): void {
    this.featuresSub?.unsubscribe();
  }

  onSubmit(): void {
    const { firstName, lastName, companyName, email, currentPassword, password, passwordConfirm } = this.form;
    if (password != passwordConfirm) {
      this.notificationService.error('New passwords do not match.');
      return;
    }

    this.authService.updateAccount(firstName, lastName, companyName, email, currentPassword, password);
  }

  createApiKey(): void {
    this.authService.createApiKey();
  }

  openCmms(): void {
    window.open('https://app.limblecmms.com/', '_blank');
  }
}
