<section>
    <div class="form-container">
      <h1 class="register-banner">Start a Free Trial Today</h1>
      <p>Activate your 30-day free trial today for your team.</p>
      
      <div class="included-features-container">
        <p>What's included:</p>
        <ul>
            <li>Unlimited searches</li>
            <li>Unlimited results</li>
            <li>Unlimited access to vendors</li>
            <li>Unlimited accounts for your team</li>
            <li>Priority customer support</li>
        </ul>
      </div>
      
      <hr />

      <div class="price-container">
        <h2 class="price">FREE</h2>
        <p class="duration">for 30 days</p>
      </div>
      <p class="trial-disclaimer">Then $150 per month ($1800 / year, billed annually)</p>

      <div class="price-row-container">
        <p>Free Trial</p>
        <p class="align-right">$0 for 30 days</p>
      </div>
      <div class="price-row-container">
        <p>Subtotal</p>
        <p class="align-right">$0</p>
      </div>
      <hr />
      <div class="price-row-container">
        <p>Total Due After Trial</p>
        <p class="align-right">$1800</p>
      </div>
      <div class="price-row-container">
        <p>Total Due Today</p>
        <p class="align-right">$0</p>
      </div>

      <button (click)="signup()" class="signup-button">Try for Free</button>

      <p class="disclaimer">Subscription will automatically renew, and your credit card linked to Limble CMMS will be charged at the end of each billing period. We'll send reminders before your trial ends.</p>
    </div>
  </section>
  