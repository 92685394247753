import { addCommandCallback } from '../utils';
const iterable = {
  initialize: function (params) {
    return addCommandCallback('gonative://iterable/initialize', params);
  },
  setEmail: function (params) {
    return addCommandCallback('gonative://iterable/setEmail', params);
  },
  setUserId: function (params) {
    return addCommandCallback('gonative://iterable/setUserId', params);
  },
  registerPush: function (params) {
    return addCommandCallback('gonative://iterable/registerPush', params);
  },
  requestPermission: function (params) {
    return addCommandCallback('gonative://iterable/requestPermission', params);
  },
  permissionStatus: function (params) {
    return addCommandCallback('gonative://iterable/permissionStatus', params);
  }
};
export default iterable;