import { Component, input } from '@angular/core';

@Component({
  selector: 'plan-price',
  standalone: true,
  imports: [],
  templateUrl: './plan-price.component.html',
  styleUrl: './plan-price.component.scss'
})
export class PlanPriceComponent {
  plan = input.required<{ name: string; price: string; period: string; description: string }>();
}
