import { addCommand, addCommandCallback } from '../utils';
function socialShareGetDataUrls(blob, params) {
  function socialShareConvertBlobToDataUrl(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob);
    });
  }
  const promises = [socialShareConvertBlobToDataUrl(blob)];
  if (params && typeof params === 'object' && params.attachment instanceof Blob) {
    promises.push(socialShareConvertBlobToDataUrl(params.attachment));
  }
  return Promise.all(promises);
}
const socialShare = {
  snapchat: {
    isInstalled: function (params) {
      return addCommandCallback('median://socialShare/snapchat/isInstalled', params);
    },
    share: function (blob, params) {
      socialShareGetDataUrls(blob, params).then(([dataUrl, attachment]) => {
        let newParams = {
          dataUrl
        };
        if (params && typeof params === 'object') {
          newParams = {
            ...params,
            dataUrl,
            attachment
          };
        }
        addCommand('median://socialShare/snapchat/share', newParams);
      });
    }
  },
  instagram: {
    isInstalled: function (params) {
      return addCommandCallback('median://socialShare/instagram/isInstalled', params);
    },
    shareToFeed: function (blob) {
      socialShareGetDataUrls(blob).then(([dataUrl]) => {
        addCommand('median://socialShare/instagram/shareToFeed', {
          dataUrl
        });
      });
    },
    shareToStory: function (blob, params) {
      socialShareGetDataUrls(blob, params).then(([dataUrl, attachment]) => {
        let newParams = {
          dataUrl
        };
        if (params && typeof params === 'object') {
          newParams = {
            ...params,
            dataUrl,
            attachment
          };
        }
        addCommand('median://socialShare/instagram/shareToStory', newParams);
      });
    }
  }
};
export default socialShare;