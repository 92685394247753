import { appInitProvider } from './app/app.init';
import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthGuardService } from './app/_services/auth-guard.service';
import { SuperAdminService } from './app/_services/super-admin-access.service';
import { httpInterceptorProviders } from './app/_helpers/http.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { provideLottieOptions, provideCacheableAnimationLoader } from 'ngx-lottie'; // for animations
import { CONFIG } from './environments/environment';
import { SubscriptionsGuardService } from './app/_services/subscriptions-guard.service';

bootstrapApplication(AppComponent, {
  providers: [
    appInitProvider,
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      RecaptchaV3Module,
      FormsModule,
      ClickOutsideModule,
      ToastrModule.forRoot({
        timeOut: 30000,
        positionClass: 'toast-top-center'
      })
    ),
    provideLottieOptions({
      player: () => import('lottie-web')
    }),
    httpInterceptorProviders,
    AuthGuardService,
    SubscriptionsGuardService,
    SuperAdminService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideCacheableAnimationLoader(),
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: CONFIG.recaptcha.siteKey }
  ]
}).catch((err) => console.error(err));
