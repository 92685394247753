import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { AuthGuardService } from '../_services/auth-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionsGuardService } from '../_services/subscriptions-guard.service';

const routes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuardService],
    title: 'Limble Search : Account',
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full'
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardService],
        title: 'Limble Search : Profile'
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
        canActivate: [AuthGuardService, SubscriptionsGuardService],
        title: 'Limble Search : Subscriptions'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
