import { addCommandCallback } from '../utils';
const msdynamics = {
  areNotificationsEnabled: function (params) {
    return addCommandCallback('median://msdynamics/areNotificationsEnabled', params, true);
  },
  promptNotificationPermission: function (params) {
    return addCommandCallback('median://msdynamics/promptNotificationPermission', params, true);
  },
  registrationInfo: function (params) {
    return addCommandCallback('median://msdynamics/registrationInfo', params, true);
  },
  registerDevice: function (params) {
    return addCommandCallback('median://msdynamics/registerDevice', params, true);
  }
};
export default msdynamics;