import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './_services/auth-guard.service';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LimbleSearchComponent } from './limble-search/limble-search.component';
import { LoadingSearch } from './loading-search/loading-search.component';
import { InternalABTestingComponent } from './internal/a-b-testing/internal-a-b-testing.component';
import { DetailsComponent } from './details/details.component';
import { TitleResolver } from './_services/title-resolver.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExtensionLanding } from './extension-landing/extension-landing.component';
import { SuperAdminService } from './_services/super-admin-access.service';
import { detailPageResolver } from './details/details.resolver';
import { AccountRoutingModule } from './account/account-routing.module';

const routes: Routes = [
  {
    path: 'results',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    title: TitleResolver
  },
  {
    path: 'results/:searchHistoryId',
    component: HomeComponent,
    canActivate: [AuthGuardService, SuperAdminService],
    title: TitleResolver
  },
  {
    path: 'explore/:searchSlug',
    component: HomeComponent,
    title: TitleResolver
  },

  { path: 'login', component: LoginComponent, title: 'Limble Search : Login' },
  { path: 'reset-password', component: ResetPasswordComponent, title: 'Limble Search : Reset Password' },
  { path: 'register', component: RegisterComponent, title: 'Limble Search : Register' },
  { path: '', component: LimbleSearchComponent },
  { path: 'searching', component: LoadingSearch },
  { path: 'details/:id', component: DetailsComponent, resolve: { itemResponse: detailPageResolver } },
  {
    path: 'internal-testing',
    component: InternalABTestingComponent
    //canActivate: [AuthGuardService], Authguard servce is always returning false, since it has to check the backend to determine if user is logged in or not. Disable for now, the route is still protected on the backend
  },
  { path: 'extension', component: ExtensionLanding },
  {
    path: 'error',
    component: ErrorPageComponent,
    title: 'Limble Search : Error'
  },
  { path: 'loading', component: LoadingSearch, canActivate: [SuperAdminService] }, //dummy route for debugging issues on loading state
  { path: '**', redirectTo: 'error', title: 'Limble Search : Error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AccountRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
