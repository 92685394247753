import { addCommand, addCommandCallback } from '../utils';
const barcode = {
  scan: function (params) {
    return addCommandCallback('median://barcode/scan', params);
  },
  setPrompt: function (prompt) {
    return addCommand('median://barcode/setPrompt', {
      prompt
    });
  }
};
export default barcode;