import { addCommandCallback } from '../utils';
const revenueCat = {
  configure: function (params) {
    return addCommandCallback('gonative://revenueCat/configure', params);
  },
  getOfferings: function (params) {
    return addCommandCallback('gonative://revenueCat/getOfferings', params);
  },
  purchase: function (params) {
    return addCommandCallback('gonative://revenueCat/purchase', params);
  },
  restorePurchases: function (params) {
    return addCommandCallback('gonative://revenueCat/restorePurchases', params);
  }
};
export default revenueCat;