import { Component, inject, OnInit, signal } from '@angular/core';
import { CmmsRegisterFormComponent } from './cmms-register-form/cmms-register-form.component';
import { ModalComponent } from '../modal/modal.component';
import { TrialThankYouComponent } from './trial-thank-you/trial-thank-you.component';
import { SignupFlyoutComponent } from '../signup-flyout/signup-flyout.component';
import { EventBusService, MetaData } from '../_shared/event-bus.service';
import { TrialService } from '../_services/trial.service';
import { TrialType } from '../_types/trialType';

@Component({
  selector: 'cmms-trial',
  standalone: true,
  imports: [CmmsRegisterFormComponent, ModalComponent, TrialThankYouComponent, SignupFlyoutComponent],
  templateUrl: './cmms-trial.component.html',
  styleUrl: './cmms-trial.component.scss'
})
export class CmmsTrialComponent implements OnInit {
  public showCMMSRegisterForm = signal(false);
  public showCMMSCustomerTrialThankYouModal = signal(false);
  private readonly eventService = inject(EventBusService);
  private readonly trialService = inject(TrialService);

  ngOnInit(): void {
    this.eventService.subscribe('CMMSSignupFlyout.Show', (callback: MetaData<boolean>) => {
      this.showCMMSRegisterForm.set(callback.data);
    });

    this.eventService.subscribe('CMMSSignUpTrialThankYouModal.Show', (callback: MetaData<boolean>) => {
      this.showCMMSCustomerTrialThankYouModal.set(callback.data);
    });
  }

  registerTrial() {
    const trialSub = this.trialService.createUserTrial(TrialType.EXISTING_CMMS);
    trialSub.add(() => {
      this.showCMMSRegisterForm.set(false);
      this.showCMMSCustomerTrialThankYouModal.set(true);
    });
  }

  hideCMMSRegisterForm() {
    this.showCMMSRegisterForm.set(false);
  }

  hideCMMSCustomerTrialThankYouModal() {
    this.showCMMSCustomerTrialThankYouModal.set(false);
  }
}
