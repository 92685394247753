import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CONFIG } from 'src/environments/environment';

const subscriptionUrl = CONFIG.API_URL + 'subscription';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);

  upgradeSubscription() {
    const user = this.authService.getUser();
    if (!user) {
      throw new Error('User not found');
    }

    return this.http.post(subscriptionUrl, { userId: user.id }, httpOptions);
  }
}
