import { Component, output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'modal',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  faXmarkLarge = faXmarkLarge;
  close = output();
}
