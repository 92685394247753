import { Component, inject, output } from '@angular/core';
import { EventBusService } from '../../_shared/event-bus.service';

@Component({
  selector: 'cmms-register-form',
  standalone: true,
  imports: [],
  templateUrl: './cmms-register-form.component.html',
  styleUrl: './cmms-register-form.component.scss'
})
export class CmmsRegisterFormComponent {
  private readonly eventService = inject(EventBusService);
  submitted = output();

  async signup() {
    console.log('TODO SEND EMAIL');
    this.eventService.emit('CMMSSignUpTrialThankYouModal.Show', true);
    this.submitted.emit();
  }
}
