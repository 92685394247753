import { addCommandCallback } from '../utils';
const contacts = {
  getAll: function (params) {
    return addCommandCallback('median://contacts/getAll', params);
  },
  getPermissionStatus: function (params) {
    return addCommandCallback('median://contacts/getPermissionStatus', params);
  },
  pickContact: function (params) {
    return addCommandCallback('median://contacts/pickContact', params);
  }
};
export default contacts;