import { addCommand } from '../utils';
const firebaseCrashlytics = {
  enable: function (enable) {
    addCommand('gonative://firebaseCrashlytics/enable', {
      enable
    });
  },
  webErrorLogsEnabled: function (enable) {
    addCommand('gonative://firebaseCrashlytics/enableWebErrorLogs', {
      enable
    });
  },
  toastErrorLogsEnabled: function (enable) {
    addCommand('gonative://firebaseCrashlytics/enableToastErrorLogs', {
      enable
    });
  },
  setUserId: function (userId) {
    addCommand('gonative://firebaseCrashlytics/setUserId', {
      userId
    });
  },
  unsetUserId: function () {
    addCommand('gonative://firebaseCrashlytics/unsetUserId');
  }
};
export default firebaseCrashlytics;