import { NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mobile-nav',
  standalone: true,
  imports: [NgIf, RouterLink, FontAwesomeModule],
  templateUrl: './mobile-nav.component.html',
  styleUrl: './mobile-nav.component.scss'
})
export class MobileNavComponent implements OnInit, OnDestroy {
  @Output() public readonly hideMobileMenu = new EventEmitter();

  public faxmark = faXmark;
  public isLoggedIn: Boolean = false;
  public authSub?: Subscription;

  private readonly authService = inject(AuthService);
  public readonly router = inject(Router);
  public readonly isCmmsUser = toSignal(this.authService.isCmmsUser$);

  ngOnInit(): void {
    this.authSub = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    console.log('isLoggedin: ', this.isLoggedIn);
  }
  hideSideMenu() {
    this.hideMobileMenu.emit();
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
  }
}
