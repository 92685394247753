import { Component, signal, input, computed, inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { faPuzzlePiece, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { AuthService } from '../_services/auth.service';
import { MobileNavComponent } from '../mobile-nav/mobile-nav.component';
import { NgClass } from '@angular/common';
import { PartSearchFieldComponent } from '../part-search-field/part-search-field.component';
import { DropdownComponent, DropdownTextItemComponent, IconComponent } from '@limblecmms/lim-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { toSignal } from '@angular/core/rxjs-interop';

import { FeatureService } from '../_services/feature.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'navigation',
  standalone: true,
  imports: [
    MobileNavComponent,
    NgClass,
    PartSearchFieldComponent,
    DropdownComponent,
    IconComponent,
    DropdownTextItemComponent,
    FontAwesomeModule,
    RouterLink
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent {
  public faPuzzlePiece: IconDefinition = faPuzzlePiece;
  isMobile = input.required<boolean>();
  isLoggedIn = input.required<boolean>();
  responsivePositioning = input.required<boolean>();

  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly currentRoute = signal('');
  public readonly isCmmsUser = toSignal(this.authService.isCmmsUser$);
  public showAuthTest = signal(false);
  showMobileMenu = signal(false);

  private readonly featureService = inject(FeatureService);
  private readonly http = inject(HttpClient);
  constructor() {
    // because the navigation component is a parent node of the <router-outlet> the activated route has no context of the current route so we need to manually look up the router events to find the current route path.
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.url);
      }
    });

    this.featureService.features$.subscribe((features) => {
      this.showAuthTest.set(this.featureService.enabled('limble-auth'));
    });
  }

  showPartSearchField = computed(() => {
    if (this.isLoggedIn()) {
      if (this.routesMatch(['/results', '/details'])) {
        if (!this.isMobile()) {
          return true;
        }
      }
    }

    return false;
  });

  showHamburger = computed(() => {
    if (!this.routesMatch(['/login', '/register'])) {
      return true;
    }

    return false;
  });

  showLinks = computed(() => {
    if (!this.isLoggedIn() && this.routesMatch(['/'])) {
      return true;
    }

    if (this.routesMatch(['/explore'])) {
      return true;
    }

    return false;
  });

  registerButtonCopy = computed(() => {
    return this.routesMatch(['explored']) ? 'Start Free Trial' : 'Sign Up';
  });

  private routesMatch(routes: string[]): boolean {
    return routes.filter((route) => this.currentRoute().includes(route)).length > 0;
  }

  toggleMobileMenu() {
    this.showMobileMenu.update((value) => !value);

    if (this.showMobileMenu()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }

  goToAccount() {
    this.router.navigate(['/account/profile']);
  }

  goToPlanAndUsage() {
    this.router.navigate(['/account/subscriptions']);
  }

  goToVendorPrefs() {
    this.router.navigate(['/'], { queryParams: { vendorPrefs: 1 } });
  }

  logout() {
    localStorage.removeItem('extensionBannerHidden');
    this.authService.logout();
  }
  // Ed - 12/4/24 Purely for Dev testing - Need to see on SEARCH-1195 auth testing
  authTestButton = () => {
    // https://app.limblecmms.com/phpscripts/manageLogin.php?action=checkLogin
    // https://app.limblecmms.com/flannel/users/self

    // Add to flannel route, the "customerName/ companyName in reap"
    // Add to flannel route, the "limble.com" domain

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true
    };

    const url = CONFIG.API_URL.includes('limblestaging.com')
      ? 'https://app.limblestaging.com'
      : 'https://app.limblecmms.com';

    // If there is a param in the location url of the window - called "customUrl" - then use that as the url instead of the default (for special url testing)
    const customUrl = new URLSearchParams(window.location.search).get('customUrl');
    const finalUrl = customUrl ? customUrl : `${url}/flannel/users/self`;

    this.http.get(finalUrl, httpOptions).subscribe({
      next: (response) => {
        debugger;
        console.log(response);
      },
      error: (error) => {
        debugger;
        console.error('There was a problem with the fetch operation:', error);
      },
      complete: () => {
        debugger;
        console.log('complete');
      }
    });
  };
}
