import { addCommandCallback } from '../utils';
const auth = {
  status: function (params) {
    return addCommandCallback('median://auth/status', params);
  },
  save: function (params) {
    if (typeof params.secret !== 'string') {
      params.secret = JSON.stringify(params.secret);
    }
    return addCommandCallback('median://auth/save', params);
  },
  get: function (params) {
    return addCommandCallback('median://auth/get', params);
  },
  delete: function (params) {
    return addCommandCallback('median://auth/delete', params);
  }
};
export default auth;