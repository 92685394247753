{
  "v": "4.8.0",
  "meta": { "g": "LottieFiles AE 3.4.5", "a": "", "k": "", "d": "", "tc": "" },
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 185.000007535204,
  "w": 1400,
  "h": 400,
  "nm": "Limble Logo NAVY",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 2,
          "ty": 3,
          "nm": "NULL ",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 0, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [960, 540, 0], "ix": 2 },
            "a": { "a": 0, "k": [50, 50, 0], "ix": 1 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [0.833, 0.833, 0.833],
                    "y": [0.833, 0.833, 1.556]
                  },
                  "o": { "x": [0.63, 0.63, 0.63], "y": [0, 0, 0] },
                  "t": 101.898,
                  "s": [100, 100, 100]
                },
                { "t": 118.881254842133, "s": [58, 58, 100] }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 3,
          "nm": "NULL CONTROL ",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 0, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.476], "y": [0.636] },
                    "o": { "x": [0.545], "y": [0] },
                    "t": 25.475,
                    "s": [50]
                  },
                  {
                    "i": { "x": [0.613], "y": [1] },
                    "o": { "x": [0.153], "y": [1.426] },
                    "t": 46.953,
                    "s": [-184.533]
                  },
                  { "t": 83.9162534179789, "s": [-214.707] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 50, "ix": 4 }
            },
            "a": { "a": 0, "k": [50, 50, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "ip": 0,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "ORANGE_C 3",
          "parent": 13,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 120, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0]
                      ],
                      "o": [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0]
                      ],
                      "v": [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.949019607843, 0.580392156863, 0.133333333333, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 30.9690012613932,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "RED_C 3",
          "parent": 13,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -120, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0]
                      ],
                      "o": [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0]
                      ],
                      "v": [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.901947021484, 0.152923583984, 0.215667724609, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 30.9690012613932,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "GREEN_C 3",
          "parent": 13,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -26.807],
                        [26.807, 0],
                        [0, 26.807],
                        [-26.807, 0]
                      ],
                      "o": [
                        [0, 26.807],
                        [-26.807, 0],
                        [0, -26.807],
                        [26.807, 0]
                      ],
                      "v": [
                        [48.539, 0],
                        [0, 48.539],
                        [-48.539, 0],
                        [0, -48.539]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.156862750649, 0.61960786581, 0.286274522543, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 30.9690012613932,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "ORANGE_C 2",
          "parent": 13,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 120, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0]
                      ],
                      "o": [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0]
                      ],
                      "v": [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.949019607843, 0.580392156863, 0.133333333333, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          "nm": "orange",
          "parent": 14,
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [0.833] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000004765508, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-267.243, 3.247, 0], "ix": 2 },
            "a": { "a": 0, "k": [-39.664, -28.811, 0], "ix": 1 },
            "s": { "a": 0, "k": [98.606, 98.606, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.12, 2.57],
                        [-0.34, 0.13],
                        [0, 0],
                        [0.08, 0.45],
                        [0, 0],
                        [0.45, 0.01],
                        [0, 0],
                        [0.14, 0.32],
                        [0.91, 1.46],
                        [0.44, -0.89],
                        [8.9, 5.62],
                        [-0.57, -1.55],
                        [-12.02, -27.23],
                        [-0.37, 0.01],
                        [-3.16, 0.51],
                        [-0.2, -0.29],
                        [-3.49, -5.3],
                        [-0.42, 0.18],
                        [0, 0],
                        [0.09, 0.44],
                        [0, 0],
                        [-0.28, 0.23],
                        [-1.83, 2.02],
                        [-0.34, -0.11],
                        [0, 0],
                        [-0.22, 0.4],
                        [0, 0],
                        [0.35, 0.29],
                        [0, 0],
                        [-0.1, 0.34],
                        [-0.46, 2.72]
                      ],
                      "o": [
                        [0.42, -2.55],
                        [0.02, -0.36],
                        [0, 0],
                        [0.43, -0.16],
                        [0, 0],
                        [-0.08, -0.44],
                        [0, 0],
                        [-0.35, -0.01],
                        [-0.83, -1.81],
                        [-0.52, -0.84],
                        [-33.49, 67.82],
                        [-1.39, -0.88],
                        [0, 0],
                        [0.15, 0.34],
                        [1.72, -0.05],
                        [0.35, -0.06],
                        [3.49, 5.3],
                        [0.25, 0.38],
                        [0, 0],
                        [0.41, -0.17],
                        [0, 0],
                        [-0.07, -0.35],
                        [2.16, -1.75],
                        [0.24, -0.26],
                        [0, 0],
                        [0.44, 0.14],
                        [0, 0],
                        [0.21, -0.39],
                        [0, 0],
                        [-0.27, -0.23],
                        [0.77, -2.62],
                        [0, 0]
                      ],
                      "v": [
                        [31.966, -14.456],
                        [32.726, -22.126],
                        [33.366, -23.016],
                        [51.196, -29.766],
                        [51.826, -30.876],
                        [48.406, -49.826],
                        [47.446, -50.646],
                        [28.316, -51.196],
                        [27.436, -51.796],
                        [24.346, -57.326],
                        [22.596, -57.226],
                        [-50.154, -7.056],
                        [-51.634, -5.866],
                        [-33.614, 41.284],
                        [-32.674, 41.884],
                        [-24.734, 41.164],
                        [-23.734, 41.594],
                        [-13.264, 57.484],
                        [-12.044, 57.854],
                        [5.696, 50.364],
                        [6.286, 49.254],
                        [2.786, 31.244],
                        [3.206, 30.234],
                        [15.376, 19.774],
                        [16.416, 19.494],
                        [34.666, 25.354],
                        [35.856, 24.874],
                        [44.986, 7.924],
                        [44.746, 6.684],
                        [30.416, -5.416],
                        [30.106, -6.456],
                        [31.956, -14.466]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.949019610882, 0.580392181873, 0.133333340287, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          "nm": "RED_C 2",
          "parent": 13,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -120, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -27.614],
                        [27.614, 0],
                        [0, 27.614],
                        [-27.614, 0]
                      ],
                      "o": [
                        [0, 27.614],
                        [-27.614, 0],
                        [0, -27.614],
                        [27.614, 0]
                      ],
                      "v": [
                        [50, 0],
                        [0, 50],
                        [-50, 0],
                        [0, -50]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.901947021484, 0.152923583984, 0.215667724609, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          "nm": "red",
          "parent": 14,
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [0.833] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000004765508, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-267.243, 3.247, 0], "ix": 2 },
            "a": { "a": 0, "k": [47.957, -27.799, 0], "ix": 1 },
            "s": { "a": 0, "k": [98.606, 98.606, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [1.06, -0.34],
                        [-39.21, -71.18],
                        [1, 0.06],
                        [1.7, 0.22],
                        [0.18, -0.29],
                        [0, 0],
                        [0.42, 0.16],
                        [0, 0],
                        [-0.08, 0.45],
                        [-1.08, 6.02],
                        [0.31, 0.19],
                        [0, 0],
                        [0, 0],
                        [1.78, 1.85],
                        [0.33, -0.11],
                        [0, 0],
                        [0.23, 0.38],
                        [0, 0],
                        [-0.35, 0.3],
                        [0, 0],
                        [0.12, 0.34],
                        [0.52, 9.91],
                        [0.33, 0.14],
                        [0, 0],
                        [-0.08, 0.44],
                        [0, 0],
                        [-0.46, 0],
                        [0, 0],
                        [-0.1, 0.23],
                        [-0.19, 0.39],
                        [0, 0],
                        [-0.34, 0],
                        [-3.54, -2.5]
                      ],
                      "o": [
                        [0.91, 0.64],
                        [-8.99, 2.88],
                        [0.48, 0.88],
                        [-1.75, -0.11],
                        [-0.35, -0.04],
                        [0, 0],
                        [-0.24, 0.38],
                        [0, 0],
                        [-0.43, -0.16],
                        [1.07, -6.02],
                        [0.07, -0.37],
                        [-2.51, -1.52],
                        [0, 0],
                        [-2.01, -1.64],
                        [-0.24, -0.25],
                        [0, 0],
                        [-0.42, 0.14],
                        [0, 0],
                        [-0.23, -0.4],
                        [0, 0],
                        [0.28, -0.24],
                        [-0.93, -2.83],
                        [-0.02, -0.36],
                        [0, 0],
                        [-0.41, -0.17],
                        [0, 0],
                        [0.08, -0.45],
                        [0, 0],
                        [0.1, -0.23],
                        [0.15, -0.35],
                        [0.21, -0.44],
                        [0.16, -0.29],
                        [35.85, 0.15],
                        [0, 0]
                      ],
                      "v": [
                        [37.157, -50.264],
                        [36.867, -48.494],
                        [41.867, 40.686],
                        [40.937, 42.176],
                        [34.867, 41.656],
                        [33.907, 42.106],
                        [23.997, 57.666],
                        [22.797, 58.066],
                        [4.777, 51.296],
                        [4.147, 50.186],
                        [7.367, 32.136],
                        [6.917, 31.116],
                        [1.407, 26.986],
                        [1.407, 26.986],
                        [-4.263, 21.746],
                        [-5.293, 21.496],
                        [-23.543, 27.556],
                        [-24.723, 27.116],
                        [-34.433, 10.486],
                        [-34.223, 9.226],
                        [-20.113, -2.944],
                        [-19.823, -4.004],
                        [-23.153, -22.504],
                        [-23.773, -23.404],
                        [-41.443, -30.534],
                        [-42.053, -31.634],
                        [-38.683, -50.594],
                        [-37.703, -51.414],
                        [-17.763, -51.554],
                        [-17.473, -52.244],
                        [-16.443, -54.474],
                        [-14.833, -57.594],
                        [-13.933, -58.124],
                        [37.157, -50.234]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.901960790157, 0.152941182256, 0.215686276555, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          "nm": "GREEN_C 2",
          "parent": 13,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, 0, 0], "ix": 2 },
            "a": {
              "a": 0,
              "k": [0, 0, 0],
              "ix": 1,
              "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control')('Slider');\n$bm_rt = [\n    0,\n    temp\n];"
            },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -26.807],
                        [26.807, 0],
                        [0, 26.807],
                        [-26.807, 0]
                      ],
                      "o": [
                        [0, 26.807],
                        [-26.807, 0],
                        [0, -26.807],
                        [26.807, 0]
                      ],
                      "v": [
                        [48.539, 0],
                        [0, 48.539],
                        [-48.539, 0],
                        [0, -48.539]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.156862750649, 0.61960786581, 0.286274522543, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 0,
                    "k": [100, 100],
                    "ix": 3,
                    "x": "var $bm_rt;\nvar temp;\ntemp = thisComp.layer('GREY').effect('Slider Control 2')('Slider');\n$bm_rt = [\n    temp,\n    temp\n];"
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          "nm": "green",
          "parent": 14,
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.833], "y": [0.833] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000004765508, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-267.243, 3.247, 0], "ix": 2 },
            "a": { "a": 0, "k": [0.253, 44.846, 0], "ix": 1 },
            "s": { "a": 0, "k": [98.606, 98.606, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.18, 0.32],
                        [-3.09, 5.43],
                        [0.35, 0.3],
                        [0, 0],
                        [0.35, -0.28],
                        [0, 0],
                        [0.32, 0.17],
                        [2.96, 0.61],
                        [0.07, 0.36],
                        [1.18, 6.2],
                        [0.46, 0],
                        [0, 0],
                        [0.09, -0.44],
                        [0, 0],
                        [0.32, -0.1],
                        [2.5, -0.89],
                        [0, 0],
                        [3.3, -1.67],
                        [0.27, 0.21],
                        [0, 0],
                        [0.34, -0.31],
                        [0, 0],
                        [-0.24, -0.38],
                        [0, 0],
                        [0.21, -0.3],
                        [0.7, -1.08],
                        [-1.07, 0.04],
                        [1.31, -9.36],
                        [0.01, -0.02],
                        [0, 0],
                        [-0.97, 0.42],
                        [-2.26, 4.15],
                        [0.16, 0.27],
                        [0.16, 0.21]
                      ],
                      "o": [
                        [-0.22, -0.29],
                        [3.09, -5.42],
                        [0.23, -0.4],
                        [0, 0],
                        [-0.34, -0.29],
                        [0, 0],
                        [-0.28, 0.22],
                        [-6.09, -3.11],
                        [-0.36, -0.07],
                        [-1.18, -6.2],
                        [-0.09, -0.45],
                        [0, 0],
                        [-0.45, 0],
                        [0, 0],
                        [-0.07, 0.32],
                        [-1.32, 0.4],
                        [0, 0],
                        [-3.33, 1.26],
                        [-0.3, 0.15],
                        [0, 0],
                        [-0.37, -0.27],
                        [0, 0],
                        [-0.33, 0.3],
                        [0, 0],
                        [0.19, 0.32],
                        [-0.9, 1.24],
                        [-0.58, 0.9],
                        [81.92, -3.52],
                        [0, 0.02],
                        [0, 0],
                        [-0.22, 1.04],
                        [11.76, -5.05],
                        [0.15, -0.28],
                        [-0.19, -0.32],
                        [0, 0]
                      ],
                      "v": [
                        [53.973, 0.531],
                        [53.903, -0.569],
                        [63.163, -16.839],
                        [62.943, -18.099],
                        [48.223, -30.509],
                        [46.963, -30.529],
                        [32.153, -18.969],
                        [31.073, -18.879],
                        [13.713, -24.999],
                        [12.933, -25.789],
                        [9.383, -44.389],
                        [8.393, -45.199],
                        [-10.857, -45.089],
                        [-11.827, -44.289],
                        [-15.737, -25.179],
                        [-16.427, -24.419],
                        [-23.587, -22.109],
                        [-23.587, -22.109],
                        [-31.427, -18.569],
                        [-32.477, -18.659],
                        [-47.607, -29.869],
                        [-48.887, -29.799],
                        [-62.977, -16.679],
                        [-63.147, -15.429],
                        [-53.997, -0.249],
                        [-54.047, 0.861],
                        [-56.607, 4.611],
                        [-55.717, 6.151],
                        [26.653, 42.991],
                        [26.643, 43.071],
                        [26.463, 43.921],
                        [27.833, 45.051],
                        [57.843, 7.191],
                        [57.823, 6.201],
                        [53.993, 0.511]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.156862750649, 0.61960786581, 0.286274522543, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 29.9700012207031,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 3,
          "nm": "GREY",
          "parent": 3,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 29, "ix": 11 },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10,
              "x": "var $bm_rt;\n$bm_rt = effect('Slider Control 3')('Slider');"
            },
            "p": {
              "s": true,
              "x": { "a": 0, "k": 50, "ix": 3 },
              "y": { "a": 0, "k": 50, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [182, 182, 100], "ix": 6 }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 5,
              "nm": "Slider Control",
              "np": 3,
              "mn": "ADBE Slider Control",
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 0,
                  "nm": "Slider",
                  "mn": "ADBE Slider Control-0001",
                  "ix": 1,
                  "v": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 0,
                        "s": [225.459]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 29.97,
                        "s": [48]
                      },
                      { "t": 44.9550018310547, "s": [2] }
                    ],
                    "ix": 1
                  }
                }
              ]
            },
            {
              "ty": 5,
              "nm": "Slider Control 2",
              "np": 3,
              "mn": "ADBE Slider Control",
              "ix": 2,
              "en": 1,
              "ef": [
                {
                  "ty": 0,
                  "nm": "Slider",
                  "mn": "ADBE Slider Control-0001",
                  "ix": 1,
                  "v": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 0,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 29.97,
                        "s": [21.3]
                      },
                      { "t": 44.9550018310547, "s": [145.3] }
                    ],
                    "ix": 1
                  }
                }
              ]
            },
            {
              "ty": 5,
              "nm": "Slider Control 3",
              "np": 3,
              "mn": "ADBE Slider Control",
              "ix": 3,
              "en": 1,
              "ef": [
                {
                  "ty": 0,
                  "nm": "Slider",
                  "mn": "ADBE Slider Control-0001",
                  "ix": 1,
                  "v": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 0,
                        "s": [-328]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 29.97,
                        "s": [-65]
                      },
                      { "t": 44.9550018310547, "s": [13] }
                    ],
                    "ix": 1
                  }
                }
              ]
            }
          ],
          "ip": 0,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 3,
          "nm": "center",
          "parent": 3,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 25, "ix": 11 },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.694], "y": [0.844] },
                  "o": { "x": [0.132], "y": [0.203] },
                  "t": 29.97,
                  "s": [-75]
                },
                {
                  "i": { "x": [0.366], "y": [1] },
                  "o": { "x": [0.194], "y": [0.607] },
                  "t": 46.454,
                  "s": [-20.961]
                },
                { "t": 85.9137534993388, "s": [0] }
              ],
              "ix": 10
            },
            "p": { "a": 0, "k": [50, 50, 0], "ix": 2 },
            "a": { "a": 0, "k": [-266.75, 3, 0], "ix": 1 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.402, 0.402, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0] },
                  "t": 14.985,
                  "s": [152.413, 152.413, 100]
                },
                { "t": 64.9350026448568, "s": [101.413, 101.413, 100] }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 239.760009765625,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 4,
          "nm": "e_matte",
          "parent": 17,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-337.272, 0.35, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": { "a": 0, "k": [98, 70], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 4 },
                  "nm": "Rectangle Path 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [652.75, -7.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Rectangle 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": { "a": 0, "k": [73, 73], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "nm": "Ellipse Path 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.1], "y": [1] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 42.458,
                        "s": [64]
                      },
                      { "t": 71.4287529093526, "s": [36] }
                    ],
                    "ix": 1
                  },
                  "e": { "a": 0, "k": 100, "ix": 2 },
                  "o": { "a": 0, "k": 0, "ix": 3 },
                  "m": 1,
                  "ix": 2,
                  "nm": "Trim Paths 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 27, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [654.75, 12.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Ellipse 1",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 42.4575017293294,
          "op": 252.247510274251,
          "st": 12.4875005086263,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 4,
          "nm": "e",
          "parent": 2,
          "tt": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 42.458,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 56.444,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000004765508, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 42.458,
                    "s": [652.79]
                  },
                  { "t": 71.4287529093526, "s": [363.79] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 109.175, "ix": 4 }
            },
            "a": { "a": 0, "k": [313.79, 59.525, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [9.82, 0],
                        [3.16, -8.65]
                      ],
                      "o": [
                        [0, 0],
                        [-2.33, -8.49],
                        [-9.15, 0],
                        [0, 0]
                      ],
                      "v": [
                        [295.56, 4.925],
                        [334.01, 4.925],
                        [315.54, -9.055],
                        [295.57, 4.925]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [12.65, 0],
                        [0, 26.46],
                        [-25.13, 0],
                        [0, -32.46],
                        [0, 0],
                        [-10.15, 0],
                        [-4.66, 4.5]
                      ],
                      "o": [
                        [0, 0],
                        [-7.66, 7.99],
                        [-25.63, 0],
                        [0, -26.46],
                        [26.96, 0],
                        [0, 0],
                        [2.83, 9.32],
                        [6.83, 0],
                        [0, 0]
                      ],
                      "v": [
                        [335.51, 30.395],
                        [350.99, 46.205],
                        [315.87, 59.525],
                        [270.27, 14.255],
                        [314.54, -30.515],
                        [357.31, 22.415],
                        [295.23, 22.415],
                        [315.7, 38.065],
                        [335.51, 30.405]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 42.4575017293294,
          "op": 252.247510274251,
          "st": 12.4875005086263,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 4,
          "nm": "l",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 40.959,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 54.945,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000254765518, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 40.959,
                    "s": [587.46]
                  },
                  { "t": 69.9302528483175, "s": [298.46] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 107.175, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 58.17, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 40.959,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-12.268, 0.83],
                              [12.032, 0.83],
                              [12.15, 58.17],
                              [-12.15, 58.17]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 54.9452522379659,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-12.15, -58.17],
                              [12.15, -58.17],
                              [12.15, 58.17],
                              [-12.15, 58.17]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 40.9590016682943,
          "op": 250.749010213216,
          "st": 10.9890004475911,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 4,
          "nm": "b",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 39.461,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 53.447,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104.001,
                  "s": [100]
                },
                { "t": 117.000504765528, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 39.461,
                    "s": [518.47]
                  },
                  { "t": 68.4317527872823, "s": [229.47] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 109.175, "ix": 4 }
            },
            "a": { "a": 0, "k": [179.47, 59.525, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39.461,
                        "s": [
                          {
                            "i": [
                              [0, 14.48],
                              [13.48, 0],
                              [1.33, -12.48],
                              [0, 0],
                              [-11.81, 0]
                            ],
                            "o": [
                              [0, -14.31],
                              [-11.82, 0],
                              [0, 0],
                              [1.33, 12.65],
                              [13.48, 0]
                            ],
                            "v": [
                              [202.19, 14.415],
                              [179.22, -10.055],
                              [157.42, 11.085],
                              [157.42, 17.745],
                              [179.22, 39.045]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 60.4392524617411,
                        "s": [
                          {
                            "i": [
                              [0, 14.48],
                              [13.48, 0],
                              [1.33, -12.48],
                              [0, 0],
                              [-11.81, 0]
                            ],
                            "o": [
                              [0, -14.31],
                              [-11.82, 0],
                              [0, 0],
                              [1.33, 12.65],
                              [13.48, 0]
                            ],
                            "v": [
                              [202.19, 14.415],
                              [179.22, -10.055],
                              [157.42, 11.085],
                              [157.42, 17.745],
                              [179.22, 39.045]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39.461,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [22.97, 0],
                              [6.82, 8.99],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-11.98, 0],
                              [0, -26.3]
                            ],
                            "o": [
                              [0, 26.46],
                              [-11.98, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [6.82, -8.82],
                              [22.97, 0],
                              [0, 0]
                            ],
                            "v": [
                              [225.82, 14.415],
                              [186.21, 59.525],
                              [157.42, 45.375],
                              [157.42, 57.525],
                              [133.12, 57.525],
                              [133.313, -16.815],
                              [157.613, -16.815],
                              [157.42, -16.535],
                              [186.21, -30.515],
                              [225.82, 14.425]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 60.4392524617411,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [22.97, 0],
                              [6.82, 8.99],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-11.98, 0],
                              [0, -26.3]
                            ],
                            "o": [
                              [0, 26.46],
                              [-11.98, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [6.82, -8.82],
                              [22.97, 0],
                              [0, 0]
                            ],
                            "v": [
                              [225.82, 14.415],
                              [186.21, 59.525],
                              [157.42, 45.375],
                              [157.42, 57.525],
                              [133.12, 57.525],
                              [133.12, -58.815],
                              [157.42, -58.815],
                              [157.42, -16.535],
                              [186.21, -30.515],
                              [225.82, 14.425]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [1, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 39.4605016072591,
          "op": 249.250510152181,
          "st": 9.49050038655599,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 4,
          "nm": "m",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 37.962,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 51.948,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 116.999504765487, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 37.962,
                    "s": [390.985]
                  },
                  { "t": 66.9332527262472, "s": [101.985] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 107.355, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 44.115, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 37.962,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [8.98, 0],
                              [0, -9.16],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [8.82, 0],
                              [0, -9.16],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-10.65, 0],
                              [-5.33, -9.66],
                              [-12.81, 0],
                              [0, -19.48]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -9.99],
                              [-9.65, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -9.99],
                              [-9.82, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [4.16, -8.16],
                              [11.82, 0],
                              [4.49, -9.49],
                              [17.98, 0],
                              [0, 0]
                            ],
                            "v": [
                              [67.315, -10.825],
                              [67.387, -6.395],
                              [44.087, -6.395],
                              [44.015, -6.655],
                              [28.705, -23.635],
                              [12.065, -7.985],
                              [11.887, 19.115],
                              [-11.413, 19.115],
                              [-11.235, -6.645],
                              [-26.385, -23.625],
                              [-43.025, -7.975],
                              [-43.025, 43.955],
                              [-67.325, 43.955],
                              [-67.325, -42.115],
                              [-43.025, -42.115],
                              [-43.025, -30.795],
                              [-19.725, -44.115],
                              [7.575, -28.465],
                              [35.535, -44.115],
                              [67.325, -10.825]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 58.940752400706,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [8.98, 0],
                              [0, -9.16],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [8.82, 0],
                              [0, -9.16],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-10.65, 0],
                              [-5.33, -9.66],
                              [-12.81, 0],
                              [0, -19.48]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -9.99],
                              [-9.65, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, -9.99],
                              [-9.82, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [4.16, -8.16],
                              [11.82, 0],
                              [4.49, -9.49],
                              [17.98, 0],
                              [0, 0]
                            ],
                            "v": [
                              [67.315, -10.825],
                              [67.315, 44.105],
                              [44.015, 44.105],
                              [44.015, -6.655],
                              [28.705, -23.635],
                              [12.065, -7.985],
                              [12.065, 44.115],
                              [-11.235, 44.115],
                              [-11.235, -6.645],
                              [-26.385, -23.625],
                              [-43.025, -7.975],
                              [-43.025, 43.955],
                              [-67.325, 43.955],
                              [-67.325, -42.115],
                              [-43.025, -42.115],
                              [-43.025, -30.795],
                              [-19.725, -44.115],
                              [7.575, -28.465],
                              [35.535, -44.115],
                              [67.325, -10.825]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [1, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 37.962001546224,
          "op": 247.752010091146,
          "st": 7.99200032552083,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 4,
          "nm": "i 2",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 36.464,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 50.45,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 116.999754765498, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 36.464,
                    "s": [297.52]
                  },
                  { "t": 65.434752665212, "s": [8.52] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 107.165, "ix": 4 }
            },
            "a": { "a": 0, "k": [-41.48, 57.515, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36.464,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-53.688, 0.465],
                              [-29.388, 0.465],
                              [-29.33, 57.515],
                              [-53.63, 57.515],
                              [-53.688, 0.475]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 57.4422523396708,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [-53.63, -28.535],
                              [-29.33, -28.535],
                              [-29.33, 57.515],
                              [-53.63, 57.515],
                              [-53.63, -28.525]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [1, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 36.4635014851888,
          "op": 246.253510030111,
          "st": 6.49350026448568,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 4,
          "nm": "i 3",
          "parent": 21,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 36.464,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 50.45,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 116.999754765498, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": { "a": 0, "k": -41.48, "ix": 3 },
              "y": { "a": 0, "k": -53.495, "ix": 4 }
            },
            "a": { "a": 0, "k": [-41.48, -53.495, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36.464,
                        "s": [
                          {
                            "i": [
                              [0, 7.33],
                              [-7.49, 0],
                              [0, -6.82],
                              [7.15, 0]
                            ],
                            "o": [
                              [0, -6.82],
                              [7.49, 0],
                              [0, 7.32],
                              [-7.15, 0]
                            ],
                            "v": [
                              [-54.688, -19.665],
                              [-41.367, -32.645],
                              [-28.387, -19.665],
                              [-41.367, -6.345]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 57.4422523396708,
                        "s": [
                          {
                            "i": [
                              [0, 7.33],
                              [-7.49, 0],
                              [0, -6.82],
                              [7.15, 0]
                            ],
                            "o": [
                              [0, -6.82],
                              [7.49, 0],
                              [0, 7.32],
                              [-7.15, 0]
                            ],
                            "v": [
                              [-54.63, -53.665],
                              [-41.31, -66.645],
                              [-28.33, -53.665],
                              [-41.31, -40.345]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 36.4635014851888,
          "op": 246.253510030111,
          "st": 6.49350026448568,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 4,
          "nm": "L",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.29], "y": [1] },
                  "o": { "x": [0.167], "y": [0.167] },
                  "t": 34.965,
                  "s": [0]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 48.951,
                  "s": [100]
                },
                {
                  "i": { "x": [0.833], "y": [1] },
                  "o": { "x": [0.167], "y": [0] },
                  "t": 104,
                  "s": [100]
                },
                { "t": 117.000004765508, "s": [0] }
              ],
              "ix": 11
            },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "s": true,
              "x": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.1], "y": [1] },
                    "o": { "x": [0.167], "y": [0.167] },
                    "t": 34.965,
                    "s": [231.485]
                  },
                  { "t": 63.9362526041769, "s": [-57.515] }
                ],
                "ix": 3
              },
              "y": { "a": 0, "k": 107.175, "ix": 4 }
            },
            "a": { "a": 0, "k": [0, 57.175, 0], "ix": 1 },
            "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.1, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 34.965,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [17.525, 34.96],
                              [17.525, 57.1],
                              [-40.775, 57.175],
                              [-40.87, -28.675],
                              [-16.24, -28.675],
                              [-16.145, 35.035]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 55.9437522786356,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0]
                            ],
                            "v": [
                              [40.775, 35.035],
                              [40.775, 57.175],
                              [-40.775, 57.175],
                              [-40.775, -57.175],
                              [-16.145, -57.175],
                              [-16.145, 35.035]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.082352941176, 0.133333333333, 0.196078431373, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [1, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 34.9650014241536,
          "op": 244.755009969076,
          "st": 4.99500020345052,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "limble_logo 7",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [700, 200, 0], "ix": 2 },
        "a": { "a": 0, "k": [960, 540, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "w": 1920,
      "h": 1080,
      "ip": 162.000006598395,
      "op": 302.000012300712,
      "st": 62.0000025253118,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      "nm": "limble_logo 7",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [700, 200, 0], "ix": 2 },
        "a": { "a": 0, "k": [960, 540, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "tm": { "a": 0, "k": 3.337, "ix": 2 },
      "w": 1920,
      "h": 1080,
      "ip": 100.000004073084,
      "op": 162.000006598395,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 0,
      "nm": "limble_logo 7",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [700, 200, 0], "ix": 2 },
        "a": { "a": 0, "k": [960, 540, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "w": 1920,
      "h": 1080,
      "ip": 0,
      "op": 100.000004073084,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
