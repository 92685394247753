<div class="profile-container">
  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <div class="section-header">User Information</div>
    @if (isCmmsUser()) {
    <div class="cmms-notice-container">
      <div class="cmms-notice-text">Information below is directly imported from Limble CMMS and cannot be edited here.</div>
      <div class="cmms-notice-button">
        <div class="cmms-notice-button-text" (click)="openCmms()">Update in CMMS</div>
        <fa-icon [icon]="arrowUpRightFromSquare"></fa-icon>
      </div>
    </div>
    }
    <div class="form-group">
      <label for="firstName" class="input-labels">First Name</label>
      <input
        type="text"
        class="form-control"
        name="firstName"
        [(ngModel)]="form.firstName"
        required
        minlength="3"
        maxlength="20"
        [disabled]="!!isCmmsUser()"
        #firstName="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && firstName.errors }"
      />
    </div>
    <div class="form-group">
      <label for="lastName" class="input-labels">Last Name</label>
      <input
        type="text"
        class="form-control"
        name="lastName"
        [(ngModel)]="form.lastName"
        required
        minlength="3"
        maxlength="20"
        [disabled]="!!isCmmsUser()"
        #lastName="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && lastName.errors }"
      />
    </div>
    <div class="form-group">
      <label for="companyName">Company Name</label>
      <input
        type="text"
        class="form-control"
        name="companyName"
        [(ngModel)]="form.companyName"
        required
        minlength="3"
        maxlength="20"
        [disabled]="!!isCmmsUser()"
        #companyName="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && companyName.errors }"
      />
    </div>
    <div class="form-group">
      <label for="email">Email Address</label>
      <input
        type="email"
        class="form-control"
        name="email"
        [(ngModel)]="form.email"
        required
        email
        [disabled]="!!isCmmsUser()"
        #email="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
      />
    </div>

    @if (!isCmmsUser()) {
    <div class="section-header" style="margin-top: 32px">Change Password</div>
    <div class="form-group">
      <label for="password">Current Password</label>
      <input
        type="password"
        class="form-control"
        name="currentPassword"
        [(ngModel)]="form.currentPassword"
        required
        minlength="6"
        #password="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
      />
    </div>
    <div class="form-group">
      <label for="password">New Password</label>
      <input
        type="password"
        class="form-control"
        name="password"
        [(ngModel)]="form.password"
        required
        minlength="8"
        #password="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
      />
    </div>
    <div class="form-group">
      <label for="password">Re-enter New Password</label>
      <input
        type="password"
        class="form-control"
        name="password"
        [(ngModel)]="form.passwordConfirm"
        required
        minlength="8"
        #password="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
      />
    </div>
    <button type="submit" class="confirm-button" (click)="onSubmit()">Confirm</button>
    }
  </form>
  @if (showApiKey) {
  <div class="api-settings">
    <h3>Search API Key</h3>
    @if (apiKey()) {
    <div class="api-key">{{ apiKey() }}</div>
    <div class="api-key-warning">
      Please note this API Key and store it in a secure place as it will not be shown again. Please refresh the key if it's
      misplaced.
    </div>
    } @else { Search API Key was last refreshed on {{ apiKeyLastRefreshed | date }}
    <button class="confirm-button" (click)="createApiKey()">{{ apiKeyLastRefreshed ? 'Refresh' : 'Create' }} API Key</button>
    }
    <div>
      See <span><a [href]="apiDocUrl" target="_blank">API documentation</a></span>
    </div>
  </div>
  }
</div>
