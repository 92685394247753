import { addCommandCallback } from '../utils';
const branch = {
  getFirstParams: function (params) {
    return addCommandCallback('median://branch/getFirstParams', params);
  },
  getLatestParams: function (params) {
    return addCommandCallback('median://branch/getLatestParams', params);
  },
  isInitialized: function (params) {
    return addCommandCallback('median://branch/isInitialized', params);
  }
};
export default branch;