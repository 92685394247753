@if (showCMMSRegisterForm()) {
  <signup-flyout (close)="hideCMMSRegisterForm()">
    <cmms-register-form (submitted)="registerTrial()" />
  </signup-flyout>
} 
@if (showCMMSCustomerTrialThankYouModal()) {
  <modal (close)="hideCMMSCustomerTrialThankYouModal()">
    <trial-thank-you (done)="hideCMMSCustomerTrialThankYouModal()" />
  </modal>
}
