import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { SearchService } from './search.service';

@Injectable()
export class AuthGuardService {
  constructor(public authService: AuthService, public router: Router, private searchService: SearchService) {}

  canActivate(): boolean {
    this.authService.subscribeCheckLogin().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['login']); // 'Login' component will authenticate and then redirect to 'LoadingSearch' since the request query will be present
        return false;
      }
      return true;
    });

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['login']); // ** normal case where user searches from app homepage
      return false;
    }

    return true;
  }
}
