import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, map, debounce, interval, Subscription, forkJoin } from 'rxjs';
import { ItemsService } from './items.service';
import { FeatureService } from './feature.service';
import { NotificationService } from './notification.service';
import { CONFIG } from '../../environments/environment';
import { ItemGroupMatch, SelectedAttributeGroups } from '../_types/attributeGrouping';
import { affliateLinks } from '../_util/constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

const searchUrl = CONFIG.API_URL + 'search';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private isSearching: boolean = false;
  private isStartingSearch: boolean = false;
  private isWaitingForResults: boolean = false;
  private pollingMsFrequency: number = 2500;
  private pollingtMsTimeout: number = 90000; // 1.5 minutes, max time we will wait for a search to finish
  private previewWaitMs: number = 15000;
  private pollingStartTime: any;
  private previewVendors: Set<number> = new Set<number>();
  private vendorsSeen: any = [];
  private previewStarted: boolean = false;
  private totalUnseenRelevantItems = 0;

  private rawResultsCached: any = [];
  private rawResults: any = [];

  private readonly _selectedItem = new BehaviorSubject<any>(null);
  public readonly _searchResults = new BehaviorSubject<any>([]);
  public readonly _filteredSearchResults = new BehaviorSubject<any>([]);
  private readonly _searchResultsMeta = new BehaviorSubject<any>([]);
  public readonly _filteredSearchResultsMeta = new BehaviorSubject<any>([]);
  public readonly _searchComplete = new BehaviorSubject<any>(false);
  public readonly _percentComplete = new BehaviorSubject<any>(100);
  public readonly _queryQuality = new BehaviorSubject<any>({});
  private readonly _partNumberFound = new BehaviorSubject<any>(false);
  private readonly _isQueryExpanded = new BehaviorSubject<any>(false);
  public readonly _isPastResult = new BehaviorSubject<boolean>(false);
  public readonly _isPreviewing = new BehaviorSubject<boolean>(false);
  public readonly _searchModalState = new BehaviorSubject<boolean>(false);
  public readonly _isMarketingMode = new BehaviorSubject<boolean>(false);
  public readonly _isItemsRestricted = new BehaviorSubject<boolean>(false);
  public readonly _isCachedQuery = new BehaviorSubject<boolean>(false);
  public readonly selectedAttributeGroups = new BehaviorSubject<SelectedAttributeGroups | null>(null);

  private savedUrl: string | null = null;
  private readonly _filters = new BehaviorSubject<any>({});
  private readonly _sortBy = new BehaviorSubject<any>('relevance');
  private readonly _query = new BehaviorSubject<any>('');
  public readonly _error = new BehaviorSubject<any>(null);
  public readonly _totalItems = new BehaviorSubject<any>(null);
  private readonly _hiddenItems = new BehaviorSubject<any>([]);
  private readonly _bestMatch = new BehaviorSubject<any>({});
  private readonly _selectedDetailsPageItem = new BehaviorSubject<any>(null);
  private readonly _hideHeroItem = new BehaviorSubject<any>(null);

  readonly searchResults$ = this._searchResults.asObservable();

  readonly selectedItem$ = this._selectedItem.asObservable();
  readonly searchResultsMeta$ = this._searchResultsMeta.asObservable();
  readonly filteredSearchResultsMeta$ = this._filteredSearchResultsMeta.asObservable();
  readonly searchComplete$ = this._searchComplete.asObservable();
  readonly percentComplete$ = this._percentComplete.asObservable();
  readonly queryQuality$ = this._queryQuality.asObservable();
  readonly partNumberFound$ = this._partNumberFound.asObservable();
  readonly isQueryExpanded$ = this._isQueryExpanded.asObservable();
  readonly isPastResult$ = this._isPastResult.asObservable();
  readonly isCachedQuery$ = this._isCachedQuery.asObservable();
  readonly isPreviewing$ = this._isPreviewing.asObservable();
  readonly searchModalState$ = this._searchModalState.asObservable();
  readonly isMarketingMode$ = this._isMarketingMode.asObservable();
  readonly isItemsRestricted$ = this._isItemsRestricted.asObservable();
  readonly selectedAttributeGroups$ = this.selectedAttributeGroups.asObservable();
  readonly filters$ = this._filters.asObservable();
  readonly sortBy$ = this._sortBy.asObservable();
  readonly bestMatch$ = this._bestMatch.asObservable();
  readonly selectedDetailsPageItem$ = this._selectedDetailsPageItem.asObservable();
  readonly hideHeroItem$ = this._hideHeroItem.asObservable();
  private pollingSub$: Subscription | undefined;
  private progressSub$: Subscription | undefined;

  private irrelevantSortVendors = ['grainger', 'motion industries', 'fastenal', 'zoro', 'mcmaster-carr'];

  readonly irrelevantResults$ = this.searchResults$.pipe(
    map((results: any) => {
      const irrelevantResults = results.filter((item: any) => {
        if (item.score <= 0) {
          return item;
        }
      });

      //sort the irrelevant results by the irrelevantSortVendors
      irrelevantResults.sort((a: any, b: any) =>
        this.irrelevantSortVendors.includes(a.vendor.name.toLowerCase()) &&
        !this.irrelevantSortVendors.includes(b.vendor.name.toLowerCase())
          ? -1
          : 1
      );
      return irrelevantResults;
    })
  );

  //groupedResults with natural query sim score higher than 0
  readonly relevantResults$ = this.searchResults$.pipe(
    map((results: any) => {
      const relevantResults = results.filter((item: any) => item.score > 0);
      return relevantResults;
    })
  );

  readonly top5GroupedSearchResults$ = this.searchResults$.pipe(
    map((results: any) => {
      return results.slice(0, 5);
    })
  );

  readonly query$ = this._query.asObservable();
  readonly error$ = this._error.asObservable();
  readonly totalItems$ = this._totalItems.asObservable();
  readonly filteredSearchResults$ = this._filteredSearchResults.asObservable();
  readonly hiddenItems$ = this._hiddenItems.asObservable();

  constructor(private itemsService: ItemsService, private featureService: FeatureService, private http: HttpClient) {}

  public processResults() {
    if (this.rawResultsCached.length > 0) {
      this.rawResults = this.rawResultsCached;
      this.rawResultsCached = [];
    }

    let finalResults = this.rawResults;
    finalResults = this.filterResults(finalResults);
    this._totalItems.next(finalResults.length);

    const deDuplicatedResults = this.deDuplicateGroups(finalResults);

    // Sort results
    const sortBy = this._sortBy.getValue();

    if (sortBy === 'price_per:asc') {
      deDuplicatedResults.sort((a: any, b: any) => a.price - b.price);
    } else if (sortBy === 'price_per:desc') {
      deDuplicatedResults.sort((a: any, b: any) => b.price - a.price);
    } else {
      deDuplicatedResults.sort((a, b) => a.rank - b.rank);
    }

    this._searchResults.next(deDuplicatedResults);
    this.updateMeta();
    if (this._sortBy.getValue() == 'relevance') {
      const bestMatch = this._bestMatch.getValue();
      if (
        !bestMatch ||
        (bestMatch && !bestMatch.id) ||
        (bestMatch && bestMatch.id && deDuplicatedResults.length && bestMatch.id !== deDuplicatedResults[0].id)
      ) {
        const topRankedResult = deDuplicatedResults.find((item: any) => item.rank !== null);
        if (topRankedResult) {
          this._bestMatch.next(topRankedResult);
        }
      }

      if (bestMatch && bestMatch.id && deDuplicatedResults.length && bestMatch.id === deDuplicatedResults[0].id) {
        const selectedAttributeGroup = this.selectedAttributeGroups.getValue();

        if (selectedAttributeGroup) {
          const groupId = Object.values(selectedAttributeGroup)[0]?.id;
          const currentMatch = bestMatch.matches.find(({ itemGroupId }) => itemGroupId === groupId) as
            | ItemGroupMatch
            | undefined;
          const newMatch = deDuplicatedResults[0].matches.find(({ itemGroupId }) => itemGroupId === groupId) as
            | ItemGroupMatch
            | undefined;

          if (currentMatch && newMatch && currentMatch.items.length !== newMatch.items.length) {
            const topRankedResult = deDuplicatedResults.find((item: any) => item.rank !== null);
            if (topRankedResult) {
              console.log('setting best match', topRankedResult.rank);
              this._bestMatch.next(topRankedResult);
            }
          }
        }
      }
    }
  }

  filterResults(results: any) {
    let filteredResults = results;
    let filters = this._filters.getValue();
    for (let field in filters) {
      filteredResults = filteredResults.filter((item: any) => {
        if (filters[field].operator === 'range') {
          if (
            item[field] >= this._filters.getValue()[field].values[0] &&
            item[field] <= this._filters.getValue()[field].values[1]
          ) {
            return true;
          }
          return false;
        } else if (filters[field].operator === 'includes') {
          if (field === 'vendorId') {
            // Loop through values and add to vendorsSeen
            filters[field].values.forEach((vendorId: any) => {
              if (!this.vendorsSeen.includes(vendorId)) {
                this.vendorsSeen.push(vendorId);
              }
            });
          }

          if (filters[field].values.includes(item[field])) {
            return true;
          }
          return false;
        }

        return true;
      });
    }

    return filteredResults;
  }

  deDuplicateGroups(results: any[]) {
    const itemGroupValueIds = new Set<number>();
    const resultSet: any[] = [];
    const selectedAttributeGroup = this.selectedAttributeGroups.getValue();

    if (selectedAttributeGroup) {
      const groupId = Object.values(selectedAttributeGroup)[0]?.id;

      results.forEach((item) => {
        if (!item.matches) {
          resultSet.push(item);
          return;
        }

        const matches = item.matches
          .filter(({ itemGroupId }) => itemGroupId === groupId)
          .sort((a, b) => b.items.length - a.items.length) as ItemGroupMatch[];
        const match = matches[0];
        if (!match) {
          resultSet.push(item);
          return;
        }

        if (itemGroupValueIds.has(match.itemGroupValueId)) {
          return;
        }

        itemGroupValueIds.add(match.itemGroupValueId);
        resultSet.push(item);
      });

      return resultSet;
    }

    return results;
  }

  getIsSearching(): boolean {
    return this.isSearching;
  }

  getTotalUnseenRelevantItems(): any {
    return this.totalUnseenRelevantItems;
  }

  getQueryQuality(query: string): Observable<any> {
    return this.http.post<any>(`${searchUrl}/query-quality`, { query }, httpOptions);
  }

  setSearchQuery(query: string): void {
    this._query.next(query);
  }

  getSearchQuery(): string {
    return this._query.getValue();
  }

  getItem(id: string): any {
    // Find item by id in search results
    let item = this._searchResults.getValue().find((item: any) => item.id == id);
    return item;
  }

  setItemLabel(itemId: any, key: string, value: any) {
    let items = this.rawResults;
    let item = items.find((item: any) => item.id == itemId);

    if (item) {
      if (typeof item.labels === 'undefined') {
        item.labels = {};
      }
      item.labels[key] = value;
    }
  }

  getItemLabel(itemId: any, key: string) {
    let items = this.rawResults;
    let item = items.find((item: any) => item.id == itemId);

    if (item && item.labels && item.labels[key]) {
      return item.labels[key];
    }
    return null;
  }

  setSortBy(sortBy: string): void {
    this._sortBy.next(sortBy);
  }

  setSelectedItem(item: any): void {
    this._selectedItem.next(item);
  }

  clearSelectedItem(): void {
    this._selectedItem.next(null);
  }

  setDetailsPageSelectedItem(item: any): void {
    this._selectedDetailsPageItem.next(item);
  }

  feedbackDislike(): void {
    this.http.post<any>(`${searchUrl}/feedback/dislike`, null).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  feedbackLike(): void {
    this.http.post<any>(`${searchUrl}/feedback/like`, null).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  feedbackComment(comments: string): void {
    this.http.post<any>(`${searchUrl}/feedback`, { comments }).subscribe({
      next: (data) => {},

      error: (err) => {}
    });
  }

  getSimilarItems(id: number, without?: any): Observable<any> {
    let commaSeperatedWithout = '';
    if (without) {
      commaSeperatedWithout = without.join(',');
    }

    return this.http.get<any>(`${searchUrl}/similar/${id}/${commaSeperatedWithout}`, httpOptions);
  }

  getLatestResults(searcHistoryId: any): void {
    // If we are still waiting for a response, don't make another request
    if (this.isWaitingForResults) {
      return;
    }
    this.isWaitingForResults = true;

    const now = Date.now();
    let timeoutExceeded = false;
    let isPreviewing = false;

    let url = `${searchUrl}/results/${searcHistoryId}`;

    if (this._isMarketingMode.getValue()) {
      url = `${searchUrl}/explore/${searcHistoryId}`;
    }

    // Safety measure. If we are still not done after X seconds, stop polling.
    // Its possible there was a problem on the backend
    if (now > this.pollingStartTime + this.pollingtMsTimeout) {
      timeoutExceeded = true;
    }

    // If we surpassed the preview wait time, we will start showing full search results while we wait
    // for the search to fully complete
    if (now > this.pollingStartTime + this.previewWaitMs) {
      isPreviewing = true;
    }

    if (isPreviewing || timeoutExceeded) {
      url = `${url}/full`;
    }

    const itemRequest = this.http.get<any>(url, httpOptions);
    const groupRequest = this.http.get<any>(`${searchUrl}/groups/${searcHistoryId}`, httpOptions);

    forkJoin([itemRequest, groupRequest]).subscribe({
      next: ([data, groupResponse]) => {
        const items = this.populateMatchGroups(data.items, groupResponse);
        this.isWaitingForResults = false;

        if (data.meta?.percentComplete === 100) {
          // Don't set completed too early.
          // this._searchComplete.next(true);
          this._percentComplete.next(0);
        } else {
          this._percentComplete.next(data.meta?.percentComplete);
        }

        this._partNumberFound.next(data.meta?.partNumberFound);
        this._isQueryExpanded.next(data.meta?.isQueryExpanded);
        this._queryQuality.next(data.meta?.queryQuality);

        if (data.meta?.query) {
          this._query.next(data.meta.query);
        }

        if (data.meta?.features) {
          this.featureService.setFeatures(data.meta.features);
        }

        if (data.meta?.vendorCounts) {
          // Before we allow the user to preview the results, filter by every vendor that is completed
          data.meta.vendorCounts.forEach((vendor: any) => {
            if (
              vendor.status !== 'loading' &&
              vendor.itemCount > 0 &&
              vendor.userEnabled &&
              vendor.vendorId &&
              !this.previewVendors.has(vendor.vendorId)
            ) {
              this.previewVendors.add(vendor.vendorId);
              const currentFilters = this._filters.getValue();
              const vendorFilter = currentFilters['vendorId'];

              const updatedFilterVendorIds = vendorFilter
                ? [...vendorFilter.values, vendor.vendorId]
                : Array.from(this.previewVendors);
              this.addFilter({
                field: 'vendorId',
                values: updatedFilterVendorIds,
                operator: 'includes'
              });
              this.processResults();
            }
          });
        }

        // If the user is already previewing the results, cache the full results for later behind the scenes
        // so the item list doesn't jump around while we load things in the background
        if (this.previewStarted && data.meta?.isFullResponse) {
          this.rawResultsCached = items;

          let totalRelevantItems = 0;
          items.forEach((item) => {
            if (this.vendorsSeen.includes(item.vendorId)) {
              return;
            }
            if (item.score > 0) {
              totalRelevantItems++;
            }
          });

          this.totalUnseenRelevantItems = totalRelevantItems;
        }

        if (data.meta?.vendorCounts) {
          data.meta.vendorCounts.forEach((vendor: any) => {
            if (this.previewVendors.has(vendor.vendorId)) {
              vendor.isFast = true;
            } else {
              vendor.isFast = false;
            }
          });
        }

        if (data.meta) {
          this._searchResultsMeta.next(data.meta);
          this._filteredSearchResultsMeta.next(data.meta);
        }

        if (!this.previewStarted) {
          this.rawResults = items;
          this.processResults();
        }

        if (isPreviewing) {
          this.previewStarted = true;
          this._isPreviewing.next(true);
        }

        if (data.meta?.complete && data.meta?.totalTasks > 0) {
          this.processResults();
          this.pollingSub$?.unsubscribe();
          this.isSearching = false;
          this._percentComplete.next(0);
          this._searchComplete.next(true);
          this._isPreviewing.next(true);
        } else if (data.meta?.percentComplete === 100) {
          // Handle possible edge case.
          this._searchComplete.next(true);
        }

        // Stop polling if we exceeded the timeout threshold before results were finished
        if (timeoutExceeded) {
          this.processResults();
          this.pollingSub$?.unsubscribe();
          this.isSearching = false;
          this._percentComplete.next(0);
          this._searchComplete.next(true);
          this._isPreviewing.next(true);
          console.warn(`Search took longer than ${this.pollingtMsTimeout}. Stopping polling.`);

          //only error if we have no results after 60 seconds
          if (this._totalItems.getValue() == 0) {
            this._error.next({ status: 'timeout', message: 'Search took too long. Stopping polling.' });
          }
        }
      },
      error: (err) => {
        this.isWaitingForResults = false;
        this.isSearching = false;
        this._percentComplete.next(0);
        this._searchComplete.next(true);
        this._isPreviewing.next(true);

        this.pollingSub$?.unsubscribe();

        // TODO: Turn this into a global error handling function to parse errors
        if (err.error) {
          try {
            const res = JSON.parse(err.error);
            this._error.next({ status: 'system', message: 'Vendor Search Error' });
          } catch {
            this._error.next({ status: 'system', message: 'Service Error' });
          }
        } else {
          this._error.next({ status: 'system', message: 'Unknown Error' });
        }
      }
    });
  }

  reset(): void {
    // If a search is already in progress, kill it
    this.pollingSub$?.unsubscribe();

    // Reset the search results
    this._partNumberFound.next(false);
    this._isQueryExpanded.next(false);
    this._isPastResult.next(false);
    this._isCachedQuery.next(false);
    this._queryQuality.next(null);
    this._percentComplete.next(0);
    this._searchComplete.next(false);
    this._searchResults.next([]);
    this._filteredSearchResults.next([]);
    this._totalItems.next(0);
    this._searchResultsMeta.next([]);
    this._filteredSearchResultsMeta.next([]);
    this._isPreviewing.next(false);
    this._filters.next({});
    this.selectedAttributeGroups.next(null);
    this.vendorsSeen = [];
    this.rawResultsCached = [];
    this.rawResults = [];
    this.previewStarted = false;
    this.totalUnseenRelevantItems = 0;
    this.previewVendors.clear();
  }

  search(query?: string | undefined): void {
    if (this.isStartingSearch) {
      return;
    }
    this.isStartingSearch = true;

    this.isSearching = true;
    this.reset();

    if (query !== undefined) {
      this._filters.next({});
      this._query.next(query);
    } else {
      query = this._query.getValue();
    }

    let params = {
      query: this._query.getValue()
    };

    if (localStorage.getItem('userLocation')) params['location'] = JSON.parse(localStorage.getItem('userLocation') || '');
    this.http.post<any>(searchUrl, params, httpOptions).subscribe({
      next: (data) => {
        this.pollingStartTime = Date.now();
        this.pollingSub$?.unsubscribe();

        if (typeof data.features !== 'undefined') {
          this.featureService.setFeatures(data.features);
        }

        if (data.id) {
          this.pollingSub$ = interval(this.pollingMsFrequency).subscribe(() => {
            this.getLatestResults(data.id);
          });
        }

        if (data?.cachedQuery) {
          this._isCachedQuery.next(true);
        }

        this.isStartingSearch = false;
      },

      error: (err) => {
        this.pollingSub$?.unsubscribe();
        this.isStartingSearch = false;

        // TODO: Turn this into a global error handling function to parse errors
        if (err.error) {
          try {
            const res = JSON.parse(err.error);
            this._error.next({ status: 'system', message: 'Vendor Search Error' });
          } catch {
            this._error.next({ status: 'system', message: 'Service Error' });
          }
        } else {
          this._error.next({ status: 'system', message: 'Unknown Error' });
        }
      }
    });
  }

  addFilter(filter: any) {
    /** 
    Example Filters:
    {
      field: "vendorId",
      values: "[1,2,3]",
      operator: "includes"
    } 

    {
      field: "pricePer",
      values: "[1,2]",
      operator: "range"
    }
    **/

    // Remove filter if it already exists
    this.removeFilter(filter.field);
    const currentFilters = this._filters.getValue();

    // Only add "includes" filter if at least one value is present
    if (filter.operator === 'includes' && filter.values.length === 0) {
      return;
    }

    // Only add "range" filter if at least one value is bounded
    if (filter.operator === 'range' && filter.values[0] === -Infinity && filter.values[1] === Infinity) {
      return;
    }

    // Add object with field, operator, and values to filters observable object, keyed by field
    this._filters.next({
      ...currentFilters,
      [filter.field]: filter
    });
  }

  removeFilter(field: string): void {
    let filters = this._filters.getValue();
    delete filters[field];
    this._filters.next(filters);
  }

  updateMeta(): void {
    try {
      const filteredMeta = {} as any;
      const vendorCounts = [] as any[];

      // add all vendors from initial search results to 'vendorCounts' array
      const searchResultsMeta = this._searchResultsMeta.getValue();
      if (searchResultsMeta && searchResultsMeta.vendorCounts)
        searchResultsMeta.vendorCounts.forEach((item: any) => {
          vendorCounts.push({
            vendorId: item.vendorId,
            vendorName: item.vendorName,
            itemCount: 0,
            priceRange: {
              highestPrice: 0,
              lowestPrice: 0
            }
          });
        });

      // To update the meta properties after filtering results,
      // we need to act separately for the price related values and the vendor related values
      // If a vendor filter is applied, we should update the price range meta but not the
      // vendor counts meta, for example. To do this, we will process each portion separately,
      // referencing the latest unfiltered results and meta values to provide
      // the updated filteredMeta values. Finally we will return both portions
      // together in the 'filteredMeta' object.

      //first, need to get the current filters so we can update meta properties correctly
      const currentFilters = this._filters.getValue();
      const unfilteredResults = this.rawResults;
      const unfilteredResultsMeta = this._searchResultsMeta.getValue();

      //PRICE RANGE META UPDATE. We will set 'globalHighestPrice' and 'globalLowestPrice' based on current vendor filter, or all vendors if no vendor filter is present
      let highestPrice = 0;
      let lowestPrice = 0;
      let selectedVendors = [] as any[];

      if (currentFilters['vendorId']) {
        selectedVendors = currentFilters['vendorId'].values;
      } else {
        //if no vendor filter is present, we will use all vendors
        selectedVendors = vendorCounts.map((item: any) => item.vendorId);
      }

      //VENDOR COUNTS META UPDATE. We will set vendorCoutns array based on current price filter, or from unfiltered meta if no price filter is present
      let selectedPriceRange = [] as any[];
      if (currentFilters['pricePer']) {
        selectedPriceRange = currentFilters['pricePer'].values;
      } else {
        //if no price filter is present, we will use all prices
        selectedPriceRange = [unfilteredResultsMeta.globalLowestPrice, unfilteredResultsMeta.globalHighestPrice];
      }

      //loop through unfiltered results and update vendorCounts array
      //loop through selectedVendors results in unfiltered results and update highestPrice and lowestPrice
      if (unfilteredResults) {
        unfilteredResults.forEach((item: any) => {
          if (selectedVendors.includes(item.vendorId)) {
            if (item.pricePer > highestPrice) {
              highestPrice = item.pricePer;
            }
            if (item.pricePer < lowestPrice || lowestPrice === 0) {
              lowestPrice = item.pricePer;
            }
          }

          if (item.pricePer >= selectedPriceRange[0] && item.pricePer <= selectedPriceRange[1]) {
            const vendorIndex = vendorCounts.findIndex((vendor: any) => vendor.vendorId === item.vendorId);
            if (vendorIndex === -1) {
              vendorCounts.push({
                vendorId: item.vendorId,
                vendorName: item.vendorName,
                itemCount: 1,
                priceRange: {
                  highestPrice: item.pricePer,
                  lowestPrice: item.pricePer
                }
              });
            } else {
              vendorCounts[vendorIndex].itemCount++;
              if (item.pricePer > vendorCounts[vendorIndex].priceRange.highestPrice) {
                vendorCounts[vendorIndex].priceRange.highestPrice = item.pricePer;
              }
              if (
                item.pricePer < vendorCounts[vendorIndex].priceRange.lowestPrice ||
                vendorCounts[vendorIndex].priceRange.lowestPrice === 0
              ) {
                vendorCounts[vendorIndex].priceRange.lowestPrice = item.pricePer;
              }
            }
          }
        });
      }

      filteredMeta['vendorCounts'] = vendorCounts;
      filteredMeta['globalHighestPrice'] = highestPrice;
      filteredMeta['globalLowestPrice'] = lowestPrice;

      this._filteredSearchResultsMeta.next(filteredMeta);
    } catch (err) {
      console.log(`ERROR WHEN UPDATING META WITH FILTERED RESULTS: ${err}`);
    }
  }

  updateFilteredResults(newFilteredResults) {
    this._filteredSearchResults.next(newFilteredResults);
  }
  updateHiddenItems(hiddenItems: any) {
    this._hiddenItems.next(hiddenItems);
  }

  resetError() {
    this._error.next(null);
  }

  hideHeroItem(item) {
    this._hideHeroItem.next(item);
  }

  toggleSearchModalState(state: boolean) {
    this._searchModalState.next(state);
  }

  setAffiliateLink(productUrl: any, vendorKey: any) {
    if (!this.featureService.enabled('affiliate-links')) {
      return productUrl;
    }
    const getAffiliate = affliateLinks.find((affiliate) => affiliate.vendorKey === vendorKey);
    if (!getAffiliate || !getAffiliate.useAffiliateLink) {
      return productUrl;
    }
    let affiliateProductUrl = productUrl;
    //extra check to make sure the url is valid
    try {
      affiliateProductUrl = new URL(productUrl);
    } catch (err) {
      console.log(err);
      return productUrl;
    }
    const { affiliateLinkType, affiliateData } = getAffiliate;
    if (affiliateLinkType === 'parameter') {
      const params = new URLSearchParams(affiliateProductUrl.searchParams);
      params.append(affiliateData.key, affiliateData.value);
      const newUrl = new URL(`${affiliateProductUrl.origin}${affiliateProductUrl.pathname}?${params.toString()}`).toString();
      return newUrl;
    }
  }

  startProgressBar() {
    this._percentComplete.next(99);
    this._searchComplete.next(false);
  }

  stopProgressBar() {
    this._searchComplete.next(true);
    this._percentComplete.next(0);
    this.progressSub$?.unsubscribe();
  }
  filterGroupItems(items: any) {
    const filters = this._filters.getValue();
    const filterKeys = Object.keys(filters);
    if (!filterKeys.length) {
      return items;
    }

    const filteredGroupItems: any = Object.keys(filters).reduce((acc, cv) => {
      const itemsToFilter = acc.length ? acc : items;
      if (cv === 'vendorId') {
        if (filters[cv].values.length === 0) {
          return itemsToFilter;
        }
        return itemsToFilter.filter((groupedItem: any) => {
          return filters[cv].values.includes(groupedItem.vendor.id);
        });
      }

      if (cv === 'pricePer') {
        return itemsToFilter.filter((groupedItem: any) => {
          return groupedItem[cv] >= filters[cv].values[0] && groupedItem[cv] <= filters[cv].values[1];
        });
      }
    }, []);

    return filteredGroupItems;
  }

  getVendorsFromFilteredGroupItems(items: any) {
    const uniqueVendorIds = new Set();
    const uniqueVendors: any = [];

    items
      .map((item) => item.vendor)
      .forEach((vendor) => {
        if (!uniqueVendorIds.has(vendor.id)) {
          uniqueVendors.push(vendor);
        }

        uniqueVendorIds.add(vendor.id);
      });

    return uniqueVendors;
  }

  populateMatchGroups(itemObj: any, groups: any) {
    if (!itemObj) {
      return [];
    }

    const rawItems = Object.values(itemObj);

    if (!groups) {
      return rawItems.map((item: any) => ({
        ...item,
        matches: []
      }));
    }

    const items = rawItems.map((item: any) => {
      if (!item.matches) {
        return {
          ...item,
          matches: []
        };
      }

      const matches: any[] = [];
      item.matches.forEach((groupId) => {
        const group = groups[groupId];

        if (group && group.items) {
          const groupItems = group.items
            .map((itemId) => itemObj[itemId])
            .filter((item) => !!item && item.pricePer > 0)
            .sort((a, b) => a.pricePer - b.pricePer);
          matches.push({
            ...group,
            items: groupItems
          });
        }
      });

      return {
        ...item,
        matches
      };
    });

    return items.sort((a, b) => a.rank - b.rank);
  }

  openItemSite(productUrl: string, vendor: any, itemId: number) {
    const openLink = this.setAffiliateLink(productUrl, vendor.key);
    this.itemsService.trackClick(itemId, 'productLink').subscribe();
    window.open(openLink, '_blank');
  }
}
