{
  "nm": "Frame 38173",
  "ddd": 0,
  "h": 506,
  "w": 315,
  "meta": { "g": "LottieFiles Figma v45" },
  "layers": [
    {
      "ty": 4,
      "nm": "Card",
      "sr": 1,
      "st": 0,
      "op": 109,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 60 },
            { "s": [100, 100], "t": 108 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 60 },
            { "s": [0], "t": 108 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
            { "s": [100], "t": 108 }
          ]
        }
      },
      "shapes": [],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "gradient-walker",
      "sr": 1,
      "st": 0,
      "op": 109,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [87.5, 224], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [87.5, 224], "t": 60 },
            { "s": [87.5, 224], "t": 108 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 60 },
            { "s": [100, 100], "t": 108 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [-90.5, 224], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [404.5, 224], "t": 60 },
            { "s": [404.5, 224], "t": 108 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 60 },
            { "s": [0], "t": 108 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
            { "s": [100], "t": 108 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [175, 0],
                      [175, 448],
                      [0, 448]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [175, 0],
                      [175, 448],
                      [0, 448]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [175, 0],
                      [175, 448],
                      [0, 448]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "gf",
          "bm": 0,
          "hd": false,
          "nm": "",
          "e": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [175.0070037841797, 194.8249969482422], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [175.0070037841797, 194.8249969482422], "t": 60 },
              { "s": [175.0070037841797, 194.8249969482422], "t": 108 }
            ]
          },
          "g": {
            "p": 3,
            "k": {
              "a": 1,
              "k": [
                {
                  "o": { "x": 0, "y": 0 },
                  "i": { "x": 0.58, "y": 1 },
                  "s": [
                    0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                    0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                    0.9490588265680799
                  ],
                  "t": 0
                },
                {
                  "o": { "x": 0, "y": 0 },
                  "i": { "x": 1, "y": 1 },
                  "s": [
                    0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                    0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                    0.9490588265680799
                  ],
                  "t": 60
                },
                {
                  "s": [
                    0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                    0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                    0.9490588265680799
                  ],
                  "t": 108
                }
              ]
            }
          },
          "t": 1,
          "a": { "a": 0, "k": 0 },
          "h": { "a": 0, "k": 0 },
          "s": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [-6.029729888723523e-7, 195.80999755859375],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [-6.029729888723523e-7, 195.80999755859375],
                "t": 60
              },
              { "s": [-6.029729888723523e-7, 195.80999755859375], "t": 108 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
              { "s": [100], "t": 108 }
            ]
          }
        }
      ],
      "ind": 2,
      "parent": 1,
      "tp": 4
    },
    {
      "ty": 4,
      "nm": "background",
      "sr": 1,
      "st": 0,
      "op": 109,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "tt": 1,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 60 },
            { "s": [100, 100], "t": 108 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 60 },
            { "s": [0], "t": 108 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
            { "s": [100], "t": 108 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0.9491, 0.9491, 0.9491], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0.9491, 0.9491, 0.9491], "t": 60 },
              { "s": [0.9491, 0.9491, 0.9491], "t": 108 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
              { "s": [100], "t": 108 }
            ]
          }
        }
      ],
      "ind": 3,
      "parent": 1,
      "tp": 4
    },
    {
      "ty": 4,
      "nm": "mask",
      "sr": 1,
      "st": 0,
      "op": 109,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "td": 1,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [133.5, 209], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [133.5, 209], "t": 60 },
            { "s": [133.5, 209], "t": 108 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 60 },
            { "s": [100, 100], "t": 108 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 233], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 233], "t": 60 },
            { "s": [157.5, 233], "t": 108 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 60 },
            { "s": [0], "t": 108 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
            { "s": [100], "t": 108 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [259, 0],
                      [267, 8],
                      [267, 232],
                      [259, 240],
                      [8, 240],
                      [0, 232],
                      [0, 8]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [259, 0],
                      [267, 8],
                      [267, 232],
                      [259, 240],
                      [8, 240],
                      [0, 232],
                      [0, 8]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 8],
                      [8, 0],
                      [259, 0],
                      [267, 8],
                      [267, 232],
                      [259, 240],
                      [8, 240],
                      [0, 232],
                      [0, 8]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 264],
                      [8, 256],
                      [259, 256],
                      [267, 264],
                      [259, 272],
                      [8, 272],
                      [0, 264]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 264],
                      [8, 256],
                      [259, 256],
                      [267, 264],
                      [259, 272],
                      [8, 272],
                      [0, 264]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 264],
                      [8, 256],
                      [259, 256],
                      [267, 264],
                      [259, 272],
                      [8, 272],
                      [0, 264]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 304],
                      [0, 312],
                      [8, 320],
                      [259, 320],
                      [267, 312],
                      [259, 304],
                      [8, 304]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 304],
                      [0, 312],
                      [8, 320],
                      [259, 320],
                      [267, 312],
                      [259, 304],
                      [8, 304]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 304],
                      [0, 312],
                      [8, 320],
                      [259, 320],
                      [267, 312],
                      [259, 304],
                      [8, 304]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 392],
                      [8, 384],
                      [259, 384],
                      [267, 392],
                      [267, 410],
                      [259, 418],
                      [8, 418],
                      [0, 410],
                      [0, 392]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 392],
                      [8, 384],
                      [259, 384],
                      [267, 392],
                      [267, 410],
                      [259, 418],
                      [8, 418],
                      [0, 410],
                      [0, 392]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 392],
                      [8, 384],
                      [259, 384],
                      [267, 392],
                      [267, 410],
                      [259, 418],
                      [8, 418],
                      [0, 410],
                      [0, 392]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 336],
                      [0, 344],
                      [8, 352],
                      [117, 352],
                      [125, 344],
                      [117, 336],
                      [8, 336]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 336],
                      [0, 344],
                      [8, 352],
                      [117, 352],
                      [125, 344],
                      [117, 336],
                      [8, 336]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 336],
                      [0, 344],
                      [8, 352],
                      [117, 352],
                      [125, 344],
                      [117, 336],
                      [8, 336]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 368],
                      [8, 360],
                      [117, 360],
                      [125, 368],
                      [117, 376],
                      [8, 376],
                      [0, 368]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 368],
                      [8, 360],
                      [117, 360],
                      [125, 368],
                      [117, 376],
                      [8, 376],
                      [0, 368]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [-4.42, 0],
                      [0, 0],
                      [0, -4.42],
                      [4.42, 0],
                      [0, 0],
                      [0, 4.42]
                    ],
                    "o": [
                      [0, -4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [-4.42, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 368],
                      [8, 360],
                      [117, 360],
                      [125, 368],
                      [117, 376],
                      [8, 376],
                      [0, 368]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 280],
                      [0, 288],
                      [8, 296],
                      [259, 296],
                      [267, 288],
                      [259, 280],
                      [8, 280]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 280],
                      [0, 288],
                      [8, 296],
                      [259, 296],
                      [267, 288],
                      [259, 280],
                      [8, 280]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, -4.42],
                      [-4.42, 0],
                      [0, 0],
                      [0, 4.42],
                      [4.42, 0],
                      [0, 0]
                    ],
                    "o": [
                      [-4.42, 0],
                      [0, 4.42],
                      [0, 0],
                      [4.42, 0],
                      [0, -4.42],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [8, 280],
                      [0, 288],
                      [8, 296],
                      [259, 296],
                      [267, 288],
                      [259, 280],
                      [8, 280]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0.9295, 0.953, 0.9647], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0.9295, 0.953, 0.9647], "t": 60 },
              { "s": [0.9295, 0.953, 0.9647], "t": 108 }
            ]
          },
          "r": 2,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
              { "s": [100], "t": 108 }
            ]
          }
        }
      ],
      "ind": 4,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "bg",
      "sr": 1,
      "st": 0,
      "op": 109,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 60 },
            { "s": [100, 100], "t": 108 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [157.5, 241.5], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [157.5, 241.5], "t": 60 },
            { "s": [157.5, 241.5], "t": 108 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 60 },
            { "s": [0], "t": 108 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
            { "s": [100], "t": 108 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 0.58, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 60
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [0, 0],
                      [315, 0],
                      [315, 483],
                      [0, 483]
                    ]
                  }
                ],
                "t": 108
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [1, 1, 1], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [1, 1, 1], "t": 60 },
              { "s": [1, 1, 1], "t": 108 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0, "y": 0 }, "i": { "x": 0.58, "y": 1 }, "s": [100], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 60 },
              { "s": [100], "t": 108 }
            ]
          }
        }
      ],
      "ind": 5,
      "parent": 1
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 60.06,
  "ip": 0,
  "assets": []
}
