import { Component, inject, computed } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TabsComponent } from './tabs/tabs.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  imports: [TabsComponent, RouterOutlet]
})
export class AccountComponent {
  private readonly authService = inject(AuthService);
  isCmmsUser = toSignal(this.authService.isCmmsUser$);

  tabs = computed<{ name: string; link: string }[]>(() => {
    const _tabs = [
      {
        name: 'Edit Profile',
        link: '/account/profile'
      }
    ];

    if (this.isCmmsUser()) {
      _tabs.push({
        name: 'Plan & Usage',
        link: '/account/subscriptions'
      });
    }

    return _tabs;
  });
}
