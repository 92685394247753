import { addCommandCallback } from '../utils';
const auth0 = {
  login: function (params) {
    return addCommandCallback('median://auth0/login', params);
  },
  logout: function (params) {
    return addCommandCallback('median://auth0/logout', params);
  },
  status: function (params) {
    return addCommandCallback('median://auth0/status', params);
  },
  profile: function (params) {
    return addCommandCallback('median://auth0/profile', params);
  },
  getCredentials: function (params) {
    return addCommandCallback('median://auth0/getCredentials', params);
  },
  renew: function (refreshToken) {
    return addCommandCallback('median://auth0/renew', {
      refreshToken
    });
  }
};
export default auth0;