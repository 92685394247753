import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';
import { SearchService } from '../_services/search.service';
import { isMobile } from '../_util/mobile.util';

import { Aliases, IconComponent } from '@limblecmms/lim-ui';
import { LogoAnimation } from '../animations/logo-animation/logo-animation.component';

import { CONFIG } from '../../environments/environment';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgClass, IconComponent, RouterLink, LogoAnimation, AsyncPipe]
})
export class LoginFormComponent implements OnInit, OnDestroy {
  loginForm: any = {
    email: null,
    password: null
  };

  forgotPasswordForm: any = {
    email: null
  };

  showForgotPasswordForm: boolean = false;
  showPassword: boolean = false;

  user: any = {};

  private loggedInSubscriber?: Subscription;
  public authErrorSubscriber?: Subscription;
  public querySub?: Subscription;

  protected eyeSlashIcon: Aliases = 'eyeSlashRegular';
  protected eyeIcon: Aliases = 'eyeRegular';

  public loading: boolean = false;
  public query: string | undefined;
  public isMobile: boolean = false;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.checkHealth();
    this.isMobile = isMobile();

    this.querySub = this.searchService.query$.subscribe((query) => {
      this.query = query;
    });

    this.loggedInSubscriber = this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.user = this.authService.getUser();
        this.authService.initMonitoring(this.user);

        if (this.query) {
          this.router.navigate(['/searching'], {
            skipLocationChange: true,
            replaceUrl: true
          });
        } else {
          this.router.navigate(['']);
        }
      }

      this.loading = false;
    });
  }

  async checkHealth(): Promise<any> {
    if (
      localStorage.getItem('healthCheck') &&
      Date.now() - parseInt(localStorage.getItem('healthCheck') || '') < 1000 * 60 * 5
    ) {
      return;
    } else {
      this.http.get(CONFIG.API_URL + 'healthPing').subscribe(
        (response) => console.log('LimSearch Online:', response),
        (error) => console.error('Health check failed:', error)
      );
      localStorage.setItem('healthCheck', Date.now().toString());
    }
  }

  ngOnDestroy(): void {
    this.loggedInSubscriber?.unsubscribe();
    this.querySub?.unsubscribe();
  }

  login(): void {
    this.loading = true;
    const { email, password } = this.loginForm;
    this.authService.login(email, password);
  }

  forgotPassword(): void {
    const { email } = this.forgotPasswordForm;
    this.router.navigate(['/complete']);
    this.authService.forgotPassword(email).subscribe({
      next: (data) => {
        this.router.navigate(['/complete']);
      },
      error: (err) => {
        this.notificationService.error(err.error.message);
      }
    });
  }

  public togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const password = document.querySelector('#password');

    //  toggle the type attribute
    const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
    password?.setAttribute('type', type);
  }
}
