{
  "nm": "Skeleton_start_MOBILE/Default",
  "ddd": 0,
  "h": 254,
  "w": 375,
  "meta": { "g": "LottieFiles Figma v61" },
  "layers": [
    {
      "ty": 0,
      "nm": "Card",
      "sr": 1,
      "st": 0,
      "op": 121.12,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
            { "s": [187.5, 127], "t": 216 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
            { "s": [100, 100], "t": 216 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
            { "s": [187.5, 127], "t": 216 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
            { "s": [0], "t": 216 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
            { "s": [100], "t": 216 }
          ]
        }
      },
      "w": 375,
      "h": 254,
      "refId": "1",
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "Skeleton_start_MOBILE/Default Bg",
      "sr": 1,
      "st": 0,
      "op": 121.12,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
            { "s": [187.5, 127], "t": 216 }
          ]
        },
        "s": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
            { "s": [100, 100], "t": 216 }
          ]
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
            { "s": [187.5, 127], "t": 216 }
          ]
        },
        "r": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
            { "s": [0], "t": 216 }
          ]
        },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
            { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
            { "s": [100], "t": 216 }
          ]
        }
      },
      "shapes": [
        {
          "ty": "sh",
          "bm": 0,
          "hd": false,
          "nm": "",
          "d": 1,
          "ks": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.33, "y": 1 },
                "i": { "x": 0.68, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [375, 0],
                      [375, 254],
                      [0, 254],
                      [0, 0]
                    ]
                  }
                ],
                "t": 0
              },
              {
                "o": { "x": 0, "y": 0 },
                "i": { "x": 1, "y": 1 },
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [375, 0],
                      [375, 254],
                      [0, 254],
                      [0, 0]
                    ]
                  }
                ],
                "t": 120
              },
              {
                "s": [
                  {
                    "c": true,
                    "i": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "o": [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0]
                    ],
                    "v": [
                      [375, 0],
                      [375, 254],
                      [0, 254],
                      [0, 0]
                    ]
                  }
                ],
                "t": 216
              }
            ]
          }
        },
        {
          "ty": "fl",
          "bm": 0,
          "hd": false,
          "nm": "",
          "c": {
            "a": 1,
            "k": [
              { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [1, 1, 1], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [1, 1, 1], "t": 120 },
              { "s": [1, 1, 1], "t": 216 }
            ]
          },
          "r": 1,
          "o": {
            "a": 1,
            "k": [
              { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
              { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
              { "s": [100], "t": 216 }
            ]
          }
        }
      ],
      "ind": 2
    }
  ],
  "v": "5.7.0",
  "fr": 60,
  "op": 120.12,
  "ip": 0,
  "assets": [
    {
      "nm": "[Asset] Card",
      "id": "1",
      "layers": [
        {
          "ty": 4,
          "nm": "mask",
          "sr": 1,
          "st": 0,
          "op": 121.12,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "td": 1,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [145.5, 89], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [145.5, 89], "t": 120 },
                { "s": [145.5, 89], "t": 216 }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
                { "s": [100, 100], "t": 216 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 130], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 130], "t": 120 },
                { "s": [187.5, 130], "t": 216 }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
                { "s": [0], "t": 216 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                { "s": [100], "t": 216 }
              ]
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0.9294, 0.9529, 0.9647], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0.9294, 0.9529, 0.9647], "t": 120 },
                  { "s": [0.9294, 0.9529, 0.9647], "t": 216 }
                ]
              },
              "r": 2,
              "o": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                  { "s": [100], "t": 216 }
                ]
              }
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "gradient-walker",
          "sr": 1,
          "st": 0,
          "op": 121.12,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "tt": 1,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [87.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [87.5, 127], "t": 120 },
                { "s": [87.5, 127], "t": 216 }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
                { "s": [100, 100], "t": 216 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [-119.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [489.5, 127], "t": 120 },
                { "s": [489.5, 127], "t": 216 }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
                { "s": [0], "t": 216 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                { "s": [100], "t": 216 }
              ]
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [175, 0],
                          [175, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [175, 0],
                          [175, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [175, 0],
                          [175, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "gf",
              "bm": 0,
              "hd": false,
              "nm": "",
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [174.99600219726562, 109.28099822998047],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [174.99600219726562, 109.28099822998047],
                    "t": 120
                  },
                  { "s": [174.99600219726562, 109.28099822998047], "t": 216 }
                ]
              },
              "g": {
                "p": 3,
                "k": {
                  "a": 1,
                  "k": [
                    {
                      "o": { "x": 0.33, "y": 1 },
                      "i": { "x": 0.68, "y": 1 },
                      "s": [
                        0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                        0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                        0.9490588265680799
                      ],
                      "t": 0
                    },
                    {
                      "o": { "x": 0, "y": 0 },
                      "i": { "x": 1, "y": 1 },
                      "s": [
                        0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                        0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                        0.9490588265680799
                      ],
                      "t": 120
                    },
                    {
                      "s": [
                        0, 0.9490588265680799, 0.9490588265680799, 0.9490588265680799, 0.5, 0.8813055115961561,
                        0.8813055115961561, 0.8813055115961561, 0.96875, 0.9490588265680799, 0.9490588265680799,
                        0.9490588265680799
                      ],
                      "t": 216
                    }
                  ]
                }
              },
              "t": 1,
              "a": { "a": 0, "k": 0 },
              "h": { "a": 0, "k": 0 },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [-6.029729888723523e-7, 111.01699829101562],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [-6.029729888723523e-7, 111.01699829101562],
                    "t": 120
                  },
                  { "s": [-6.029729888723523e-7, 111.01699829101562], "t": 216 }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                  { "s": [100], "t": 216 }
                ]
              }
            }
          ],
          "ind": 2
        },
        {
          "ty": 4,
          "nm": "mask",
          "sr": 1,
          "st": 0,
          "op": 121.12,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "td": 1,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [145.5, 89], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [145.5, 89], "t": 120 },
                { "s": [145.5, 89], "t": 216 }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
                { "s": [100, 100], "t": 216 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 130], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 130], "t": 120 },
                { "s": [187.5, 130], "t": 216 }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
                { "s": [0], "t": 216 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                { "s": [100], "t": 216 }
              ]
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-2.06, 0],
                          [0, 0],
                          [0, 2.06],
                          [0, 0],
                          [2.06, 0],
                          [0, 0],
                          [0, -2.06],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [3.73, 0],
                          [0, 3.73],
                          [0, 116.27],
                          [3.73, 120],
                          [116.27, 120],
                          [120, 116.27],
                          [120, 3.73],
                          [116.27, 0],
                          [3.73, 0]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [8, 144],
                          [0, 152],
                          [0, 170],
                          [8, 178],
                          [283, 178],
                          [291, 170],
                          [291, 152],
                          [283, 144],
                          [8, 144]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 18],
                          [154, 10],
                          [283, 10],
                          [291, 18],
                          [283, 26],
                          [154, 26],
                          [146, 18]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 37],
                          [146, 45],
                          [154, 53],
                          [283, 53],
                          [291, 45],
                          [283, 37],
                          [154, 37]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42]
                        ],
                        "o": [
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        "v": [
                          [146, 72],
                          [154, 64],
                          [267, 64],
                          [275, 72],
                          [267, 80],
                          [154, 80],
                          [146, 72]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, -4.42],
                          [-4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [4.42, 0],
                          [0, 0]
                        ],
                        "o": [
                          [-4.42, 0],
                          [0, 4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, -4.42],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [154, 91],
                          [146, 99],
                          [154, 107],
                          [267, 107],
                          [275, 99],
                          [267, 91],
                          [154, 91]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0.9294, 0.9529, 0.9647], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0.9294, 0.9529, 0.9647], "t": 120 },
                  { "s": [0.9294, 0.9529, 0.9647], "t": 216 }
                ]
              },
              "r": 2,
              "o": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                  { "s": [100], "t": 216 }
                ]
              }
            }
          ],
          "ind": 3
        },
        {
          "ty": 4,
          "nm": "background",
          "sr": 1,
          "st": 0,
          "op": 121.12,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "tt": 1,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
                { "s": [187.5, 127], "t": 216 }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
                { "s": [100, 100], "t": 216 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
                { "s": [187.5, 127], "t": 216 }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
                { "s": [0], "t": 216 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                { "s": [100], "t": 216 }
              ]
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0.949, 0.949, 0.949], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0.949, 0.949, 0.949], "t": 120 },
                  { "s": [0.949, 0.949, 0.949], "t": 216 }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                  { "s": [100], "t": 216 }
                ]
              }
            }
          ],
          "ind": 4
        },
        {
          "ty": 4,
          "nm": "bg",
          "sr": 1,
          "st": 0,
          "op": 121.12,
          "ip": 0,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
                { "s": [187.5, 127], "t": 216 }
              ]
            },
            "s": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100, 100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100, 100], "t": 120 },
                { "s": [100, 100], "t": 216 }
              ]
            },
            "sk": { "a": 0, "k": 0 },
            "p": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [187.5, 127], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [187.5, 127], "t": 120 },
                { "s": [187.5, 127], "t": 216 }
              ]
            },
            "r": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [0], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [0], "t": 120 },
                { "s": [0], "t": 216 }
              ]
            },
            "sa": { "a": 0, "k": 0 },
            "o": {
              "a": 1,
              "k": [
                { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                { "s": [100], "t": 216 }
              ]
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "nm": "",
              "d": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "o": { "x": 0.33, "y": 1 },
                    "i": { "x": 0.68, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 0
                  },
                  {
                    "o": { "x": 0, "y": 0 },
                    "i": { "x": 1, "y": 1 },
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 120
                  },
                  {
                    "s": [
                      {
                        "c": true,
                        "i": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "o": [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        "v": [
                          [375, 0],
                          [375, 254],
                          [0, 254],
                          [0, 0]
                        ]
                      }
                    ],
                    "t": 216
                  }
                ]
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "nm": "",
              "c": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [1, 1, 1], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [1, 1, 1], "t": 120 },
                  { "s": [1, 1, 1], "t": 216 }
                ]
              },
              "r": 1,
              "o": {
                "a": 1,
                "k": [
                  { "o": { "x": 0.33, "y": 1 }, "i": { "x": 0.68, "y": 1 }, "s": [100], "t": 0 },
                  { "o": { "x": 0, "y": 0 }, "i": { "x": 1, "y": 1 }, "s": [100], "t": 120 },
                  { "s": [100], "t": 216 }
                ]
              }
            }
          ],
          "ind": 5
        }
      ]
    }
  ]
}
