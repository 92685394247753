import { Component, input } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'tabs',
  standalone: true,
  imports: [TabComponent],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  tabs = input.required<{ name: string; link: string }[]>();
}
